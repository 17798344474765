import { useEffect, useState, useRef } from "react";


const AGudStory = (props) => {

        
    return (
        <div className="wrapagudstory">
            <br />
            <h2>Read ze "A Gud Story"</h2>  

                <a href="https://twitter.com/zegolemz" target="_blank">
                    Follow ze Story on Bird App
                </a>
                <br /><br />
                <a href="/"><div className="button leavebuttonblack">
                    Close
                </div>
            </a>
            <br /><br />

            <h3>Intermission Part 1</h3>

            <a href="/AGudStory" className="button leavebuttonblack">
                { "< Back" }
            </a>
            &nbsp;
            <a href="/AGudStory/3" className="button leavebuttonblack">
                { "Next >" }
            </a>
            
            <div className="agudchapter">

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/Intro.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/Intro2.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                       After Anon and Donatell0 have reached ze Pool Club,<br />
                       Ze Story continues on April 8th, 2023 in Big Teck's Factory.<br />
                       Big Teck isn't happy.
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission1/1.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/2.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/3.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/4.jpg" className="agudimgsmall"></img>
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        1
                    </div>
                </div>

                <div className="agudchapterinside">
                    <div className="activation">
                       Back in ze Pool Club, Donatell0 starts to arrange things...
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission1/5.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/6.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/7.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/8.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/9.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/10.jpg" className="agudimgsmall"></img>

                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        2
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/11.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/12.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Ze Meatdata Update caused an explosion...<br />
                        Somebody spilled a lot of ze gud Ketschup in ze Pool Club.<br />
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission1/12A.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/13.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/14.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/15.jpg" className="agudimgsmall"></img>  
                    <img loading="lazy" src="/AGudStory/Intermission1/16.jpg" className="agudimgsmall"></img>    
                    <div className="activation">
                       Faye Knewz investigates the explosion and gets kidnapped!
                    </div>              
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        3
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/17.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/18.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/19.jpg" className="agudimg"></img>

                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        4
                    </div>
                </div>

                <div className="agudchapterinside">
                    <div className="activation">
                       Time to get serious!
                    </div>  
                    <img loading="lazy" src="/AGudStory/Intermission1/20.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/21.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/22.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/23.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/24.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/25.jpg" className="agudimgsmall"></img>                   
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        5
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/26.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/27.gif" className="agudimgsmall"></img>
                    <div className="activation">
                       D0n is in trouble!!!<br />
                       He needs Anon's help as soon as possible!
                    </div>  
                    <img loading="lazy" src="/AGudStory/Intermission1/28.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/29.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/30.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/31.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                       Thanks to the genius of zeMaliciouz and BrotherClaude,<br />
                       D0n is saved and ze Story can continue.
                    </div>
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        6
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/32.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/33.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/34.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/35.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/36.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/37.jpg" className="agudimgsmall"></img>                     
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        7
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/38.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/39.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/40.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/41.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/42.jpg" className="agudimgsmall"></img> 
                    <img loading="lazy" src="/AGudStory/Intermission1/ShowerUnlocked.jpg" className="agudimgsmall"></img>                    
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        8
                    </div>
                </div>

                <div className="agudchapterinside">
                    <div className="activation">
                       Ze Sh0wer was unlocked!<br />
                       Anon can now <a href="https://www.golemz.wtf/TermsOfService" target="_blank">sign up his Golemz for ze Adventure.</a><br />
                       Back in ze Cafeteria, Frank is still cleaning up.
                    </div> 
                    <img loading="lazy" src="/AGudStory/Intermission1/43.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/44.jpg" className="agudimgsmall"></img>

                    <img loading="lazy" src="/AGudStory/Intermission1/45.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/46.jpg" className="agudimgsmall"></img>

                    <img loading="lazy" src="/AGudStory/Intermission1/47.jpg" className="agudimg"></img>                   

                
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        9
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/48.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/49.jpg" className="agudimgsmall"></img>                    
                    <img loading="lazy" src="/AGudStory/Intermission1/50.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/51.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Faye is trapped in ze Dungeon!!!<br />
                        Back in ze Pool Club a Golemz was named...<br />
                        Benedict!
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission1/52.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/53.jpg" className="agudimgsmall"></img>
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        10
                    </div>
                </div>  

                 <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/54.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/55.jpg" className="agudimgsmall"></img>                    
                    <img loading="lazy" src="/AGudStory/Intermission1/56.jpg" className="agudimg"></img>
                    <div className="activation">
                        After Benedict's Shower, ze Story continues in ze Cafeteria...
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission1/57.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/58.jpg" className="agudimgsmall"></img>
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        11
                    </div>
                </div>   
                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/59.jpg" className="agudimgsmall"></img>        
                    <img loading="lazy" src="/AGudStory/Intermission1/60.jpg" className="agudimgsmall"></img>                    
                    <img loading="lazy" src="/AGudStory/Intermission1/61.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/62.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Frank is really angry with Buck...<br />
                        Thanks to Golemz Jonny however, he is comforted<br />and finishes cleaning up ze Cafeteria.
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission1/63.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/64.jpg" className="agudimgsmall"></img>                    
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        12
                    </div>
                </div>   

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission1/65.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/66.jpg" className="agudimgsmall"></img>                    
                    <img loading="lazy" src="/AGudStory/Intermission1/67.jpg" className="agudimg"></img>
                    <img loading="lazy" src="/AGudStory/Intermission1/CafeteriaUnlocked.jpg" className="agudimg"></img>

                    <div className="activation">
                        Even though Frank can't use his Cash Register and access ze Tokenz,<br />
                        he has opened ze Cafeteria where Anon can browse through his Guestbook.<br />
                        The end of Intermission 1 is reached.
                    </div>
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        13
                    </div>
                </div>      


                

            </div>

            <h3>Summary of Intermission 1</h3>

            <div className="wrapsummary">

                <div className="summaryblock">

                    <img loading="lazy" loading="lazy" src="/AGudStory/Intermission1/4.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Big Teck's disciple is called Egozero</div>
                    
                </div>
                
                <div className="summaryblock">

                    <img loading="lazy" loading="lazy" src="/AGudStory/Intermission1/7.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Some Golemz are more degenerate</div>  

                </div>


                <div className="summaryblock">

                    <img loading="lazy" src="/AGudStory/Intermission1/unlocks/ShowerUnlocked.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Ze Shower was activated</div>   
                    
                </div>

                <div className="summaryblock">

                    <img loading="lazy" src="/AGudStory/Intermission1/unlocks/Gomputer.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Ze Golemz can be named</div>   
                    
                </div>

                <div className="summaryblock">
                    <img loading="lazy" loading="lazy" src="/AGudStory/Intermission1/51.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Faye Knewz was kidnapped</div>
                </div>

                
                <div className="summaryblock">

                    <img loading="lazy" src="/AGudStory/Intermission1/60.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Ze Tokenz are stuck</div>   
                    
                </div>

                <div className="summaryblock">
                    <img loading="lazy" loading="lazy" src="/AGudStory/Intermission1/65.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Ze Guestbook tracks ze Golemz</div>
                </div>

                <div className="summaryblock">
                    <img loading="lazy" loading="lazy" src="/AGudStory/Intermission1/CafeteriaUnlocked.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Buck's Cafeteria is open</div>
                </div>

                <div className="summaryblock">
                    <img loading="lazy" src="/AGudStory/Intermission1/unlocks/BeatzByDrDegen.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Bonus: Golemz love Elevator Music</div>
                </div>

                <div className="clearfix"></div>

            </div>
            
            <br /><br />
            <h3>To be continued...</h3>

            <br />
            <a href="https://sudoswap.xyz/#/browse/buy/0x6503bf993a1529314337f357847b1B042f85E116" target="_blank">
                Get a Golemz and become part of ze Story
            </a><br /><br />
            <a href="https://twitter.com/zegolemz" target="_blank">
                Follow ze Story on Bird App
            </a>
            
        </div>
    );
};

export default AGudStory;
