import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./poolclub.css";
import App from "./App";
import Goopaper from "./Goopaper";
import reportWebVitals from "./reportWebVitals";

import Riddle from "./Riddle";
import PoolClub from "./PoolClub";
import AGudStory from "./AGudStory";
import APIdata from "./APIdata";
import PageNotFound from "./PageNotFound";
import TermsOfService from "./TermsOfService";
import { UserProvider } from "./poolclub/User";

import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";



ReactDOM.render(
    <React.StrictMode>
        <UserProvider >
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App />} />
                    <Route path="/Goopaper" element={<Goopaper />} />
                    <Route path="/Riddle" element={<Riddle />} />
                    <Route path="/PoolClub" element={<PoolClub />} >
                        <Route path=":id" element={<PoolClub />} />
                    </Route>   

                    <Route path="/Shower" element={<PoolClub />} >
                        <Route path=":id" element={<PoolClub />} />
                    </Route> 

                    <Route path="/Cafeteria" element={<PoolClub />} >
                        <Route path=":id" element={<PoolClub />} />
                    </Route>   

                    <Route path="/Shop" element={<PoolClub />} >
                        <Route path=":id" element={<PoolClub />} />
                    </Route>

                    <Route path="/APIdata" element={<APIdata />} />

                    <Route path="/AGudStory" element={<AGudStory />} >
                        <Route path=":id" element={<AGudStory />} />
                    </Route>

                    <Route path="/TermsOfService" element={<TermsOfService />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </BrowserRouter>
        </UserProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
