
import "./App.css";
import PageNotFoundC from "./PageNotFoundC";


import { useEffect, useState } from "react";

const App = (props) => {
    useEffect(() => {
        const caller = async () => {};
        caller().then(() => {});


        return () => {};
    }, []);

    return (
        <div className="pagenotfound">
            {
                <div>
                    <PageNotFoundC />
                </div>
            }
        </div>
    );

};


export default App;
