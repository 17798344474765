import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useUser } from "./poolclub/User";

import {
    connectWallet,
    disconnectWallet,
    getCurrentWalletConnected,
    setWa,
    getWa,
    getGolemz,
    getGolemzList
} from "./utils/interact.js";

import { useNavigate } from 'react-router-dom';


const Header = (props) => {

    const user = useUser();
    const navigate = useNavigate();

    const box = useRef(null);
    const [clickOwnAGolemz,setClickOwnAGolemz] = useState(false);
    let c = 0;

    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [loginError, setLoginError] = useState(false);

    const [golemz, setGolemz] = useState(false);
    const [golemzDetails, setGolemzDetails] = useState(false);


    const [audio] = useState(new Audio("/golemzOG.wav"));
    let sound = useRef();

    let gm = useRef();
    let don = useRef();

    useEffect(() => {
        const caller = async () => {
            const { address, status } = await getCurrentWalletConnected();
            initWallet(address);
            addWalletListener();

            
        };
        caller().then(() => {
            

        });

        

    }, []);

    
    const addWalletListener = () => {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    initWallet(accounts[0]);
                } else {
                    initWallet("");
                }
            });

            window.ethereum.on("disconnect", () => {
                initWallet("");
                setStatus("");
                alert("disconnected");
            });
        } else {
            setStatus(
                "<p> 🦊 <a target='_blank' href='https://metamask.io/download.html'>You must install Metamask, a virtual Ethereum wallet, in your browser.</a></p>",
            );
        }
    }

    const initWallet = (address) => {
        setWallet(address);
        setGolemzForWallet(address);
    }

    function touchGolemz(e) { 
       e.target.style.bottom = "0px";
    }

    function notouchGolemz(e) { 
 
        e.target.style.bottom = "-15px"; 
     }

    sound?.current?.addEventListener('click', () => {
		try{
            audio.play();

		} catch(error)
		{
			console.log(error);
		}
		
		
	});	

    function golemgm() {  
        let e = document.getElementById("golemgm");
        e.style.display = "block";
     }

     function nogolemgm() { 
        let e = document.getElementById("golemgm");
        e.style.display = "none";
     }


    const setGolemzForWallet = async (address) => {
        let gz = await getGolemz(address);
        setGolemz(gz);

        let gDs = await getGolemzList(address);
        setGolemzDetails(gDs);

    }

     const connectWalletPressed = async () => {
        let walletResponse = await connectWallet();

        if (walletResponse.address === "") {
            setLoginError(true);
        } else {
            setLoginError(false);
            setWallet(walletResponse.address);
        }
    };

    const enterPoolClub = () => {
        let room = user.getCurrentRoom();

        if(room === null)
        {
            room = "PoolClub";
        }

        navigate(room);

    }

     




    return (
        <div>

            <div className="bydonatello">

                <a href="https://twitter.com/donbuidl0r" target="_blank" ref={don} onMouseEnter={golemgm} onMouseLeave={nogolemgm}>
                    <img src="donatell0-compr.jpg" />
                </a>

                <img src="golemgm.png" id="golemgm"  />

            </div>

            <div className="maintitle">

                <h2>
                    We are ze
                </h2>
                
                <h1>
                    Golemz
                </h1>

                {walletAddress !== "" && (
                    <div className="currentwallet">                        
                        
                        <div>
                            Connected as<br />
                            <span className="walletAddress">{walletAddress}</span>
                        </div>                                            
                        
                    </div>
                )}  

                
                { walletAddress ? (
                    <div >

                        Connected
                        <br />
                        <h4>You own { golemz } Golemz</h4>
                        
                        <div className="golemzamount">
                            { golemz > 25 ? (
                                <div>
                                    wwaaaraaAAAaawwrrraAA<br />FFFFuuuu ze Tampup!!!<br />FFFuuuu Big Teck!!!!! 
                                </div>
                            ) : golemz > 10 ? (
                                <div>
                                   Man, thanks for supporting ze Golemz.<br />You should be safe from Big Teck.<br />Have fun in ze Golemz Pool Club!
                                </div>
                            )
                            : golemz >= 7 ?(
                                <div>
                                    { golemz } Golemz?!<br />Welcome to ze Pool Club my brother.<br />Enjoy your stay! Drinkz on us!
                                </div>
                            ) : golemz >= 5  ?(
                                <div>
                                    { golemz } Golemz?!<br />Sounds like you're gonna have a good time in ze Pool Club.<br />hehehehehe
                                </div>
                            ) : golemz >= 3 ? (
                                <div>
                                    { golemz } Golemz?!<br />Guess you should be rather safe from chugging ze Tampup...<br />
                                    Oh and...
                                </div>
                            )
                            : golemz >= 2 ? (
                                <div>
                                    Only { golemz } Golemz?!<br />Well good luck with that! lol<br />
                                    Go ahead...
                                </div>
                            ) : golemz >= 1  ? (
                                <div>
                                    Only { golemz } Golemz?!<br />You'll probably need to chug ze Tampup...<br />
                                    Well...
                                </div>
                            ) : (
                                <div>
                                    Ser, do you really want to chug ze Tampup?! <br/>
                                    Get some Golemz!!!
                                </div>                            
                            )
                            }

                        </div>
                        
                        <br />
                        
                        {
                            golemz >= 1 ?
                            (   
                                <div>

                                        <div className="wrappoollogo" onClick={ () => enterPoolClub() }>

                                            You may enter ze

                                            <div className="">

                                            {/*golemzDetails?.map((item, index) => (
                                                <div key={`item__${index}`}>
                                                
                                                test
                                                </div>
                                            ))*/}

                                            <img src="Poolclub-w.png" className="poolclublogo"></img>


                                            </div>

                                        </div>

                                    <br /><br />
                                    <a href="https://sudoswap.xyz/#/browse/buy/0x6503bf993a1529314337f357847b1B042f85E116" target="_blank">Get more Golemz</a>
                                </div>
                            ) : (
                                <div>
                                    <br /><br />
                                    <a href="https://sudoswap.xyz/#/browse/buy/0x6503bf993a1529314337f357847b1B042f85E116" target="_blank">Get Golemz NOW!!!</a>
                                </div>
                            )
                        }

                        
                        
                        
                    </div>

                ) : (
                    <div>
                        <div className="claimButton"  onClick={connectWalletPressed} ref={sound}>
                            Own a Golemz?
                            <div className="smallfont">
                                Connect ze Wallet
                            </div>
                        </div>
                        <br /><br />
                        <a href="https://sudoswap.xyz/#/browse/buy/0x6503bf993a1529314337f357847b1B042f85E116" target="_blank">Get a Golemz first</a>
                        <br /><br />
                        {loginError && (
                            <div className="loginerror">
                                Could not connect wallet. <br />
                                <span className="smallfont">
                                    Do you have{" "}
                                    <a href="https://metamask.io" target="_blank">
                                        Metamask
                                    </a>{" "}
                                    installed? <br />
                                    Right now only Metamask wallet is supported.
                                </span>
                            </div>
                        )}


                    </div>

                    

                    
                )}
                

                
                 
                

            </div>

            <div className="golems" >
                <img src="g1.png" className="g g1" onMouseEnter={touchGolemz} onMouseLeave={notouchGolemz}></img>
                <img src="g2.png" className="g g2" onMouseEnter={touchGolemz} onMouseLeave={notouchGolemz}></img>
                <img src="g3.png" className="g g3" onMouseEnter={touchGolemz} onMouseLeave={notouchGolemz}></img>
                <img src="g4.png" className="g g4" onMouseEnter={touchGolemz} onMouseLeave={notouchGolemz}></img>
                <img src="g5.png" className="g g5" onMouseEnter={touchGolemz} onMouseLeave={notouchGolemz}></img>
                <img src="g6.png" className="g g6" onMouseEnter={touchGolemz} onMouseLeave={notouchGolemz}></img>

                
                
            </div>

            <div className="agudstory">
                <a href="/AGudStory">
                    <img src="AGudStory.png"></img>
                </a>

            </div>


            <script>

                

            </script>

        </div>
        
        
   );
};

export default Header;
