import { useEffect, useState, useRef } from "react";

import {
    Link
  } from "react-router-dom";

import {
    triggerSuccessfulEffect,
    triggerUnsuccessfulEffect
} from "./actions.js";

import { useUser } from "./User";

const RoomPoolClub = (props) => {

    let user = useUser();

    useEffect(() => {

            user.setCursor();
            user.setCurrentRoom("PoolClub");

            console.log(user.getCurrentRoom);

    }, []);
   

    /* GAME LOGIC */

    const checkBruv = useRef();
    const hitBruv = useRef();
    const talkBruv = useRef();
    const hitDerp = useRef();
    const talkDerp = useRef();
    const missingLeo = useRef();
    const cocktail = useRef();
    const hitCocktail = useRef();

    const playMusic = useRef();

    const [audio] = useState(new Audio("/poolclubvibe.mp3"));
    const [audioclick] = useState(new Audio("/click.mp3"));
	const [musicplaying, setMusicPlaying] = useState(false);

    const [audioWa] = useState(new Audio("/golemzOG.wav"));

    const checkAction = (e) => {

        unsetActions(); 

        if(e === "clickBruv")
        {
            if(user.mode === "inspect")
            {
                doInspect("A fat Golemz called 'Bruv'");
            }
            else if(user.item === "zegudketschup" && user.mode === "use")
            {
                checkBruv.current.style.display = "block";
                triggerSuccessfulEffect("*Oooohhhh*",user.setEffect);
            } 
            else if(user.item === "crowbar" && user.mode === "use")
            {
                hitBruv.current.style.display = "block";
                triggerSuccessfulEffect("*Ooiiiii*",user.setEffect);
            }  
            else if(user.mode === "talk")
            {
                talkBruv.current.style.display = "block";
                triggerSuccessfulEffect("*Sup*",user.setEffect);
            }           
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        else if (e === "clickDerp")
        {
            if(user.mode === "inspect")
            {
                doInspect("A funny Golemz called 'Derp'");
            }
            else if(user.mode === "talk")
            {                
                talkDerp.current.style.display = "block";
                triggerSuccessfulEffect("*hehehehe*",user.setEffect);
            }
            else if(user.item === "crowbar" && user.mode === "use")
            {
                hitDerp.current.style.display = "block";
                triggerSuccessfulEffect("*Dong*",user.setEffect);
            }
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
            
        } 
        else if (e === "clickMissing")
        {
            if(user.mode === "inspect")
            {
                doInspect("A poster of a missing Golemz");
            }
            else if(user.mode === "pull")
            {
                missingLeo.current.style.display = "block";
                triggerSuccessfulEffect("*Poor Leo*",user.setEffect);
            }
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        else if (e === "clickCocktail")
        {
            if(user.mode === "inspect")
            {
                doInspect("A cocktail made with ze gud ketschup");
            }
            else if(user.mode === "pickup")
            {
                cocktail.current.style.display = "block";
                triggerSuccessfulEffect("*Slurrrppp*",user.setEffect);
            }
            else if(user.mode === "use" && user.item === "crowbar")
            {
                hitCocktail.current.style.display = "block";
                audioWa.play();	
                triggerSuccessfulEffect("*Pling*",user.setEffect);
                window.setTimeout(() => {window.location="/"},1500)
            }
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        if(e === "playMusic")
        {
            if(user.mode === "inspect")
            {
                doInspect("A 'Beatz by Dr. Degen' Ghettoblaster");
            }
            else if(user.mode === "use")
            {
                
                if(!musicplaying)
                {
                    setMusicPlaying(true);
                    audioclick.play();	
                    window.setTimeout(() => {audio.play();}, 200);		

                    audio.addEventListener('ended', function () {
                        this.currentTime = 0;
                        this.play();
                    }, false);			
                }
                else
                {
                    setMusicPlaying(false);
                    audioclick.play();
                    window.setTimeout(() => {audio.pause();}, 200);	
                    
                }

                triggerSuccessfulEffect("*Click*",user.setEffect);
            }
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        else{
            triggerUnsuccessfulEffect(user.setEffect);
        }
    }

    const unsetActions = () =>
    {
        hitDerp.current.style.display = "none";
        talkDerp.current.style.display = "none";
        checkBruv.current.style.display = "none";
        hitBruv.current.style.display = "none";
        talkBruv.current.style.display = "none";
        missingLeo.current.style.display = "none";
        cocktail.current.style.display = "none";
        hitCocktail.current.style.display = "none";
    }

    const doInspect = (e) => {
        triggerSuccessfulEffect(e,user.setEffect);
    }

    

    
    return (
        <div>

            { user.golemz > 0 ? (
                <div>
                    <div className="wrappoolclub">    

                            <br />

                            <div className="poolclubzone">

                                <div ref={hitDerp} className="speechbubble speechbubble-derp" style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-derp-text">
                                        Yo degen, chill!<br />
                                        You need to relax<br />some more...
                                    </div>

                                </div>

                                <div ref={talkDerp} className="speechbubble speechbubble-derp" style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-derp-text">
                                        Welcome to ze Poo...<br />
                                        *Pfffrt*<br />
                                        Ayyy I farted.
                                    </div>

                                </div>

                                <div ref={missingLeo} className="speechbubble speechbubble-derp" style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-derp-text">                                    
                                        That's our leader<br /><h3>Leo.</h3>
                                        He's been missing<br />
                                        for a few days...
                                    </div>

                                </div>

                                <div ref={cocktail} className="speechbubble speechbubble-derp" style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-derp-text">
                                        That's my<br />
                                        gud cocktail, degen.<br />
                                        Try it!<br />
                                        It's like ketchup mixed<br />
                                        with goblin blood!
                                    </div>

                                </div>

                                <div ref={checkBruv} className="speechbubble speechbubble-bruv"style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-bruv-text">
                                        Thx Degen, <br />
                                        keep scrubbing my ass<br />
                                        with ze gud ketschup!
                                    </div>

                                </div>

                                <div ref={hitBruv} className="speechbubble speechbubble-bruv"style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-bruv-text">
                                        Ooooiiiiii yeahh<br />
                                        Keep scratching<br />
                                        my crotch, degen! <br />
                                        I'm gonna faaaart.
                                    </div>

                                </div>

                                <div ref={talkBruv} className="speechbubble speechbubble-bruv"style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-bruv-text">
                                        G(ole)m degen!<br />
                                        Nice to meet you <br />
                                        Come fart in the water <br />
                                        with us...
                                    </div>

                                </div>

                                <div ref={hitCocktail} className="speechbubble speechbubble-bruv"style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-bruv-text">
                                        Okay degen,<br />
                                        STOP IT!!!<br />
                                        That's our cocktail!!!<br />
                                        Go get some fresh air!
                                    </div>

                                </div>


                                <img src="/Bruv.png" className="bruv" onClick={() => checkAction("clickBruv") } />
                                <img src="/Derp.png" className="derp" onClick={() => {checkAction("clickDerp")} }/>

                                <img src="/beatz.png" className="playMusic" ref={playMusic} onClick={() => {checkAction("playMusic")} }/>

                                <div className="missing" onClick={() => {checkAction("clickMissing")} }></div>
                                <div className="cocktail" onClick={() => {checkAction("clickCocktail")} }></div>

                                <img src="/poolclubbg.jpg" className="bg" />

                            
                            </div>

                    </div>

                    <Link to="/PoolClub/2">
                        <div className="specialaction">
                                "G(ole)m?"
                        </div>
                    </Link>
                    

                </div>

            ) : user.golemz == 0 ? (
                <div className="wrapmissinggolem">

                    <img src="/MISSINGGOLEM.png" className="missinggolem"/>

                </div>
            ) : (
                <div >
                </div>
            )}
            
        </div>
    );
};

export default RoomPoolClub;
