import { useEffect, useState, useRef } from "react";

import {
    connectWallet,
    disconnectWallet,
    getCurrentWalletConnected,
    setWa,
    getWa,
    getGolemz,
    getGolemzList,
} from "../utils/interact.js";

import {

} from "./actions.js";

import { useUser } from "./User";

require("dotenv").config();

const PoolClubMenu = (props) => {

    const user = useUser();

    const golemzSelection = useRef();
    const inventorySelection = useRef();
    const showGolemzSelection = useRef();
    const showInventory = useRef();

    useEffect(() => {
     
        (async () => {
            let sg = user.getSelectedGolemz();

            if(sg !== null && sg !== undefined && sg.name !== undefined)
            {
                user.saveAndSelectGolemz(user,sg);
            }
            else
            {
                user.saveAndSelectGolemz(user,null);
            }
            
        })();

    }, []);

    useEffect(() => {    



    }, [user]);


    /* GAME LOGIC */

    const selectMode = (e) => {
        if(user.mode === e) user.setMode('move');
        else user.setMode(e);
    }

    const selectItem = (e) => {
        if(user.item === e) user.setItem('no item');
        else user.setItem(e);
    }          

    const openPopup = (e) => {
        e.current.classList.add("show");
        user.setCanMoveWithKeys(false);
        
    }

    const closePopup = (e) => {
        e.current.classList.remove("show");
        user.setCanMoveWithKeys(true);  
    }

    const loadGolemz = () => { 
        if(user.canMenu)
        {
            openPopup(showGolemzSelection);
        }
       
    }

    const selectGolemz = (e) => {   

        let id;

        if(e.id !== undefined)
            id = parseInt(e.id.tokenId);
        else id = -1;

        if(id!==user.selectedGolemz.id)
        {
            let golemPortraits = document.querySelectorAll(".golemPortrait");

            golemPortraits.forEach(box => {
                box.classList.remove('selected');
            });

            let selectedG = {};
            selectedG.id = id;
            
            if(e.name != "")
            {
                selectedG.name = e.name;
            }
            else
            {
                selectedG.name = e.metadata.name;
            }
            

            selectedG.img = e.metadata.thumbnail_url;
            
            user.saveAndSelectGolemz(user,selectedG);

            let ele = document.getElementById("Golemz_"+id);
            ele.classList.add("selected");
        }
        else
        {

            let ele = document.getElementById("Golemz_"+id);
            ele.classList.remove("selected");

            let selectedG = {};
            selectedG.id = -1;
            selectedG.name = "Select Golemz";
            selectedG.img = user.defaultchar;

            user.saveAndSelectGolemz(user,selectedG);
        }
    }

    const loadInventory = () => {    
        openPopup(showInventory);
    }

    const sendApiCall = async (e) => {
        
        await fetch("https://strapi.golemz.wtf/api/actions", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + process.env.REACT_APP_STRAPI_TOKEN,
            },
            method: "POST",
            body: JSON.stringify({
                data: {
                    golemz: null,
                    name: "Test",
                    action: "test",
                    dateReceived: new Date()
                }
            })
        })
        .then((res) => res.json())
        .then((res) => {
            console.log(res)
        })

    }
    
    
    return (
            <div>

                <div className="characterSelection wrappopup hidden" ref={showGolemzSelection}>
                    <div className="popup" >
                        <div className="golemzSelectionPopup">
                            <h3>Select Golemz</h3>
                            <div ref={golemzSelection} className="golemzList">

                            { user.golemzDetails?.ownedNfts ? (

                                <div className="scrollGolemz">  
                                    {user.golemzDetails?.ownedNfts?.map((item,i) => (
                                        <div className="golemPortrait" key={"Golemz_"+parseInt(item.id.tokenId)} id={"Golemz_"+parseInt(item.id.tokenId)} onClick={ () => {selectGolemz(item);}}>
                                            <div>
                                               <img src={item.metadata.thumbnail_url} loading="lazy" />
                                               {item.name !== "" ? (
                                                <span>
                                                    { item.name }
                                                </span>
                                               ) : (
                                                <span>
                                                    { item.metadata.name }
                                                </span>
                                               )}
                                            </div>
                                        </div>
                                        
                                    ))}
                                    <div className="clearfix"></div>
                                </div> 

                            ) : (
                                <div>  
                                    Loading
                                </div> 
                                )

                            }
                            </div>

                            <div className="closebutton" onClick={() => {closePopup(showGolemzSelection)}}>
                                Close
                            </div>
                        </div>
                    </div>
                </div>


                <div className="inventorySelection wrappopup hidden" ref={showInventory}>
                    <div className="popup" >
                        <div className="" ref={inventorySelection}>

                            <div className="closebutton">
                                Close
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menuzone">
                    <div className="wrapmenuzone">
                        <div className="effectfield" id="effectfield">
                            {user.effect}
                        </div>
                        <div className="select">
                            {user.mode} with {user.item}
                        </div>

                        <div className="charactericon">

                            <img src={user.selectedGolemz?.img} id="charactericon" onClick={() => {loadGolemz();}} />

                            <div className="charactername" >
                                {user.selectedGolemz?.name}
                            </div>

                        </div>

                        <div className="left">
                            <div className={"b " + (user.mode==="use" ? " active": "")} id="use" onClick={() => selectMode('use')}>Use</div>
                            <div className={"b " + (user.mode==="give" ? " active": "")} id="give" onClick={() => selectMode('give')}>Give</div>
                            <div className={"b " + (user.mode==="pickup" ? " active": "")} id="pickup" onClick={() => selectMode('pickup')}>Pick up</div>
                            <div className={"b " + (user.mode==="open" ? " active": "")} id="open" onClick={() => selectMode('open')}>Open</div>
                            <div className={"b " + (user.mode==="close" ? " active": "")} id="close" onClick={() => selectMode('close')}>Close</div>
                            <div className={"b " + (user.mode==="talk" ? " active": "")} id="talk" onClick={() => selectMode('talk')}>Talk</div>
                            <div className={"b " + (user.mode==="push" ? " active": "")} id="push" onClick={() => selectMode('push')}>Push</div>
                            <div className={"b " + (user.mode==="pull" ? " active": "")} id="pull" onClick={() => selectMode('pull')}>Pull</div>
                            <div className={"b " + (user.mode==="inspect" ? " active": "")} id="inspect" onClick={() => selectMode('inspect')}>Inspect</div>
                        </div>

                        <div className="right">
                            
                            <div className="wrapItems">
                                <div className={"b " + (user.item==="zegudketschup" ? " active": "")} id="zegudketschup" onClick={() => selectItem('zegudketschup')}>
                                    <img src="/item/zegudketschup.jpg" />
                                </div>
                                <div className={"b " + (user.item==="crowbar" ? " active": "")} id="crowbar" onClick={() => selectItem('crowbar')}>
                                    <img src="/item/weapon_crowbar.jpg" />
                                </div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                                <div className="b" onClick={() => selectItem('no item')}></div>
                            </div>
                            
                        </div>

                        <div className="inventoryicon">

                            <img src="/item/backpack.png" id="backpack" onClick={() => {}} />

                        </div>                        

                        <div className="clearfix"></div>

                    </div>
                </div>

            </div>
    );
};

export default PoolClubMenu;
