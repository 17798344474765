import { useEffect, useState, useRef } from "react";

import {
    triggerSuccessfulEffect,
    triggerUnsuccessfulEffect
} from "./actions.js";

import { useUser } from "./User";
import { setWa, waitForGolemzNamed } from "../utils/interact.js";

import {
    Link
} from "react-router-dom";

import { useNavigate } from 'react-router-dom';

/*
ABOUT THIS ROOM:
Bruv and Anon are taking a shower.
Ze Golemz are named.
*/

const RoomShop = (props) => {

    let user = useUser();


   

    /* GAME LOGIC */

    const Bomber = useRef();
    const BomberTalking = useRef();
    const computer = useRef();
    const computerOverlay = useRef();
    const step1 = useRef();
    const step2 = useRef();
    const step3 = useRef();
    const waInput = useRef();
    const showerFog = useRef();
    const weaponCardRef = useRef();
    const navigate = useNavigate();

    const specialaction = useRef();
    const poolclubzone = useRef();

    const [weaponCard, setWeaponCard] = useState("");

    const [audioWa] = useState(new Audio("/golemzOG.wav"));
    const [schrubschrub] = useState(new Audio("/sounds/schrubschrub.mp3"));

    useEffect(() => {

        console.log("golemz",user.golemz);

        console.log("GM");
        


    }, []);

    useEffect(() => {



        user.setCursor();
        user.setCurrentRoom("Shop");

        window.addEventListener('keydown', navigateWithKeys);
        

        return () => { window.removeEventListener('keydown', navigateWithKeys) ;}
      

    }, [user]);


    const resetRoom = () => {
        resetSpeechBubbles();        
        Bomber.current.classList.remove("movecharoutbottom");

        specialaction.current.classList.add("hidden");
        specialaction.current.classList.remove("fadein");

        chooseText("A0","","happy");
    }

    const checkAction = (e) => {

        console.log(e);

        unsetActions();

        if(e === "checkBomber")
        {
            if(user.mode === "inspect")
            {
                doInspect("A Golemz called 'Bomber'. He sells ze Weapons.");
            }      
            if(user.mode === "talk")
            {
                resetSpeech();   
                chooseText("A0","","happy");
            }            

        } 
        else if(e === "clickVase")
        {
            if((user.item == "crowbar" && (user.mode === "use")) || user.mode == "pull" || user.mode == "push" )
            {
                resetSpeech(); 
                chooseText("C1","","angry")
            } 

            if(user.mode === "inspect")
            {
                doInspect("A grey empty vase.");
            }  
        }
        else if(e === "clickFireworks")
        {
            if(user.mode === "pickup")
            {
                resetSpeech(); 
                chooseText("C2","","happy")
            } 

            if(user.mode === "inspect")
            {
                doInspect("Some fireworks from last year's New Year's Eve.");
            }  
        }   
        else if(e === "clickSmallbox")
        {
            if(user.mode === "inspect")
            {
                doInspect("Just a small wooden box.");
            }  
        }  
        else if(e === "clickTripod")
        {
            if(user.mode == "push" || user.mode == "pull")
            {
                resetSpeech(); 
                chooseText("C3","","happy")
            } 

            if(user.mode === "inspect")
            {
                doInspect("A Tripod, but the head is missing.");
            }  
        }   
        else if(e === "clickSale")
        {
            if(user.mode === "inspect")
            {
                doInspect("A sign that says 'Sale'. Can't you read?");
            }  
        }  
        else if(e === "axe")
        {
            if(user.mode === "inspect")
            {
                doInspect("A gud Axe. It looks very sharp.");
            }      
            if(user.mode === "pickup" || user.mode === "pull" || user.mode === "use")
            {
                showWeapon(e);
            }    
        } 
        else if(e === "bomb")
        {
            if(user.mode === "inspect")
            {
                doInspect("A bomb that looks like a... bowling ball?");
            }      
            if(user.mode === "pickup" || user.mode === "pull" || user.mode === "use")
            {
                showWeapon(e);
            }    
        } 
        else if(e === "gazooka")
        {
            if(user.mode === "inspect")
            {
                doInspect("ZE ultimate weapon: ze Gazooka.");
            }      
            if(user.mode === "pickup" || user.mode === "pull" || user.mode === "use")
            {
                showWeapon(e);
            }    
        } 
        else if(e === "noodle")
        {
            if(user.mode === "inspect")
            {
                doInspect("It looks like... just a pool noodle?");
            }      
            if(user.mode === "pickup" || user.mode === "pull" || user.mode === "use")
            {
                showWeapon(e);
            }    
        } 
        else if(e === "nunchaku")
        {
            if(user.mode === "inspect")
            {
                doInspect("Two computer mice that are connected so intelligently that they feel like a nunchaku.");
            }      
            if(user.mode === "pickup" || user.mode === "pull" || user.mode === "use")
            {
                showWeapon(e);
            }    
        } 
        else if(e === "fork")
        {
            if(user.mode === "inspect")
            {
                doInspect("Lots of good memories with Broseidon's fork!");
            }      
            if(user.mode === "pickup" || user.mode === "pull" || user.mode === "use")
            {
                showWeapon(e);
            }    
        } 
        else if(e === "closeWeapon")
        {
            if(user.mode === "close" || user.mode === "use" || user.mode == "pickup")
            {
                closeWeapon(e);
            }    
        } 
        else{
            triggerUnsuccessfulEffect(user.setEffect);
        }

        
    }


    const resetSpeech = () => {
        chooseText("","A0","happy"); 
        chooseText("","A1","happy");     
        chooseText("","A2","happy"); 
        chooseText("","A3","happy"); 
        chooseText("","A4","happy"); 
        chooseText("","A5","happy"); 
        chooseText("","C1","happy"); 
        chooseText("","C2","happy"); 
        chooseText("","C2B","happy"); 
    }

    const showWeapon = (weapon) => {
        setWeaponCard("card_"+weapon+".jpg");
        weaponCardRef.current.classList.remove("weaponhidden");
    }

    const closeWeapon = (weapon) => {
        setWeaponCard("card_"+weapon+".jpg");
        weaponCardRef.current.classList.add("weaponhidden");
    }



    const resetSpeechBubbles = () => {
    }

    const checkActionMD = (e) => {

        /*if (e=== "schrubSchrub")
        {
            if(user.mode === "use")
            {
                resetSpeechBubbles();  
                schrubschrub.play();
                checkBruv.current.classList.remove("show");	
                Bruv.current.classList.add("schrubschrub");
                schrubbing.current.classList.add("show");

            }
        }*/
    }

    const checkActionMU = (e) => {

        /*if (e === "schrubSchrub")
        {
            if(user.mode === "use")
            {
                resetSpeechBubbles();  
                schrubschrub.pause();
            }	           
        }*/
    }

    const talkTo = (char) => {

    }

    const unsetActions = () =>
    {

    }

    const doInspect = (e) => {
        triggerSuccessfulEffect(e,user.setEffect);
    }

    const doStep = (e,eToRemove) =>{

        if(e !== "")
            e.current.classList.add("show");

        if(eToRemove !== "")
            eToRemove.current.classList.remove("show");
        
    }

    const closeGomputer = () => {
        
        specialaction.current?.classList.add("fadein");       
        specialaction.current.classList.remove("hidden");

        user.setCanMoveWithKeys(true);
    }

    const chooseText = (e,eToRemove,h) => {

        if(e === undefined)
            e = "";

        if(eToRemove === undefined)
            eToRemove = "";

        let i;
        let r;

        if(e !== "")
            i = document.getElementById(e); //show

        if(eToRemove !=="" )
            r = document.getElementById(eToRemove); //hide


        if(i !== null && i !== undefined)
            i.classList.remove("hidden");

        if(r !== null && r !== undefined)
            r.classList.add("hidden"); 

        if(eToRemove === "all")
        {            
            let textboxes = document.querySelectorAll(".wraptextBubble");

            textboxes.forEach(box => {
                box.classList.add("hidden");
            });
        }
        

        if(h === "happy")
        {
            Bomber.current.classList.add("happy");
        }
        else if(h === "unhappy")
        {
            Bomber.current.classList.add("unhappy");
        }


        /* SPECIAL CONDITIONS */
        if(eToRemove === "A7")
        {
            
        }
    }
   

    const goTo = (roomUrl) => {
        navigate(roomUrl);

        /*poolclubzone.current.classList.add("blurout3");
        window.setTimeout(() => {}, 3000);    */    

    }

    const navigateWithKeys = (event) => {

        if(user.canMoveWithKeys)
        {
            switch( event.keyCode ) {
                case 68:
                    goTo("/PoolClub/3");
                    break;
                default: 
                    break;
            }
        }

    }


    
    return (
        <div>

            { user.golemz > 0 ? (
                <div >
                    <div className="wrappoolclub wrappoolclublarge blurinroom">    
                    
                            <div className="poolclubzonelarge" ref={poolclubzone}>

                                <div className="wraptextBubble wraptextBubbleLeft" id="A0">
                                    
                                    <div className="text">
                                        'sup Degen. I'm so happy to finally welcome you in my Shop...<br />
                                        Just look at all these handsome Weapons!
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A1","A0","happy")} }>
                                        >Great to see you Bomber!
                                    </div>

                                    <div className="choicebutton red" onClick={ () => {chooseText("","A0","happy")} }>
                                        >I know what to do, so just show me what you got in store!
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A1">
                                    
                                    <div className="text">
                                        Since you helped me open ze Box, I will make you a special price...<br />
                                        I hope you have your <span className="orange">ze Tokenz</span> ready!
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A2","A1","happy")} }>
                                        >I don't think I have any of <span className="orange">ze Tokenz</span>...
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A2">
                                    
                                    <div className="text">
                                        WHAT?! How do you mean? <br />
                                        Do you want to tell me that you're poor?                      
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A4","A2","happy")} }>
                                        >Yes, I'm poor!
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A3","A2","happy")} }>
                                        >I don't even know what <span className="orange">Ze Tokenz</span> are...
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A3">
                                    
                                    <div className="text">
                                      I can't believe it, Degen...<br />
                                      <span className="orange">Ze Tokenz</span> are our money! They're being used all throughout Ze City.<br />
                                      They're being printed by <span className="orange">ze Bankerz</span>! How can you not know that?
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A4","A3","happy")} }>
                                        >Sorry, Bomber... I guess I'm just... stupid?
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A4">
                                    
                                    <div className="text">
                                       Well okay... If you don't have any of <span className="orange">Ze Tokenz</span> then<br />
                                       I don't think there's much I can do for you now...
                                        
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A5","A4","happy")} }>
                                        >Can I touch ze Weapons at least?
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A5">
                                    
                                    <div className="text">
                                        Ah yes of course! Feel free to <span className="green">Pick Up</span> any of ze Weapons and have a closer look!<br />
                                        I'll wait here.
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("","A5","happy")} }>
                                        >Thanks Bomber! I'll have a look.
                                    </div>
                                    <div className="choicebutton red verysmallfont" onClick={ () => {chooseText("A0","A5","happy")} }>
                                        >I'm stupud. Can you repeat all of that?
                                    </div>

                                </div>


                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="C1">
                                    
                                    <div className="text">
                                    DEGEN STOP THAT RIGHT NOW!!!!
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("","C1","happy")} }>
                                        >Ok Ok! You don't have to shout...
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="C2">
                                    
                                    <div className="text">
                                        Yeah those were good times...<br />
                                        New Year's Eve 2022... I remember it like it was yesterday...
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("C2B","C2","happy")} }>
                                        >What happened?
                                    </div>

                                </div>
                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="C2B">
                                    
                                    <div className="text">
                                        Well we partied here at ze Pool Club until 3 o'clock in the morning! <br />
                                        We danced to Dr. Degen's beats and drank ze gud Ketschup like there was no tomorrow! <br />
                                        For once we could forget all the troubles outside of ze Pool Club...
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("","C2B","happy")} }>
                                        >Haha sounds like fun!
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="C3">
                                    
                                    <div className="text">
                                        Hey can you please leave the Tripod in peace?<br />
                                        It needs to be standing at this exact location. <br />
                                        We'll need it later!
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("","C3","happy")} }>
                                        >Okay... Sorry!
                                    </div>

                                </div>

                                <img src="/weapons/axe.png" className="shopweapon axe" onClick={ () => {checkAction('axe') }}/>
                                <img src="/weapons/bomb.png" className="shopweapon bomb" onClick={ () => {checkAction('bomb') }}/>
                                <img src="/weapons/gazooka.png" className="shopweapon gazooka" onClick= { () => {checkAction('gazooka')}}/>
                                <img src="/weapons/noodle.png" className="shopweapon noodle" onClick={ () => {checkAction('noodle') }}/>
                                <img src="/weapons/nunchaku.png" className="shopweapon nunchaku" onClick={ () => {checkAction('nunchaku')}}/>
                                <img src="/weapons/fork.png" className="shopweapon fork" onClick={ () => {checkAction('fork')}}/>

                                <div className="weaponCard weaponhidden" ref={weaponCardRef} onClick={ () => {checkAction('closeWeapon')}}>
                                    <img src={ "/weapons/"+weaponCard } />
                                </div>
                               

                                <img src="/rooms/bombersshop_top.png" className="bombersshop_top" />

                                <img className="bomberinshopLeft bomberinshop" src="/chars/Bomber.png" ref={Bomber} onClick={() => {checkAction("checkBomber")} }/>      

                                <img src="/rooms/bombersshop_bottom.jpg" className="bombersshop_bottom" />

                                <div className="clickVase" onClick={() => {checkAction("clickVase")} }></div>
                                <div className="clickFireworks" onClick={() => {checkAction("clickFireworks")} }></div>      
                                <div className="clickSmallbox" onClick={() => {checkAction("clickSmallbox")} }></div>   
                                <div className="clickTripod" onClick={() => {checkAction("clickTripod")} }></div>  
                                <div className="clickSale" onClick={() => {checkAction("clickSale")} }></div>  

                                <div className="navigation navright">                                    
                                        <div className="navbutton" onClick={() => {goTo("/PoolClub/3")}}>
                                            D
                                        </div>
                                </div>
                            
                            </div>                       

                    </div>


                    <div className="wrappoolclub nowrappoolclublarge"> 
                         <br />
                        <h2>
                            Degen, your screen is too small to enjoy this room.
                        </h2>
                        <h1>
                            Please switch to a bigger screen.
                        </h1>
                    </div>

                </div>


            ) : user.golemz == 0 ? (
                <div className="wrapmissinggolem">

                    <img src="/MISSINGGOLEM.png" className="missinggolem"/>

                </div>
            ) : (
                <div >
                </div>
            )}
            
        </div>
    );
};

export default RoomShop;
