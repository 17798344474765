
import "./App.css";
import GoopaperC from "./GoopaperC";


import { useEffect, useState } from "react";

const Goopaper = (props) => {
    useEffect(() => {
        const caller = async () => {};
        caller().then(() => {});


        return () => {};
    }, []);

    return (
        <div className="Goopaper">
            {
                <div>
                    <GoopaperC />
                </div>
            }
        </div>
    );

};


export default Goopaper;
