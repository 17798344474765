const Story = (props) => {
    return (
        <div>
            <div className="golemcontainer">

                <div className="divider"></div>
                <a href="/">Back</a>

                <div className="divider"></div>
                <h2>Ze Golemz</h2><br ></br>
                <h1>Goo paper</h1> 
                <br />               
                <p className="">by  <a href="https://twitter.com/donbuidl0r" target="_blank">donatell0</a></p> 
                "I am leaving this paper for you to learn about my creation..."<br />

                <br />

                <div className="goopapertext">
                    <br />
                    <h1 className="tiltleft">&nbsp;&nbsp;My notes</h1>
                    <br /><br />
                    <p>
                        In a crazy experiment, I, <a href="https://twitter.com/donbuidl0r" target="_blank">donatell0</a>, have created<br />
                        <h2><a href="https://etherscan.io/address/0x6503bf993a1529314337f357847b1b042f85e116" target="_blank">300 Golemz</a></h2>
                        that are now trapped on the Ethereum blockchain.<br /><br />

                        They need your help. <br /><br />

                        For protection, I have locked them in a <a href="https://sudoswap.xyz/#/swap/0x43d1262197ae2d0a6a968343c4478b662c3cdfe3" target="_blank">Sudoswap Trading pool</a>.<br />
                        It costs 0.0001ETH to unlock the first one.<br />
                        But there is a price delta of 5%.<br />
                        This means that the second Golem will cost 0.000105ETH...<br />
                        The third 0.00011025ETH...<br />
                        And so on.<br />
                        The last Golem can be unlocked for 206.2581523ETH.<br /><br />

                        Every Golem consists of 3 parts:<br />
                        ze Goo, ze Tech & ze Golem.<br /><br />

                        All Golemz are equal, but some are more equal than others.<br />
                        The pool will determine which Golemz deserve to live and which will remain in their 'cage'.<br /><br />
                        
                        Owning a Golem gives you:<br />
                        - access to join my <a href="https://discord.gg/WNcNpfuQEY" target="_blank">token gated Golemz chats</a>.<br />
                        - commercial rights over your Golemz while you hold the NFT.<br />
                        - is worth 0.<br /><br />

                        If you don't like your Golemz anymore, you can simply resell them to the pool. Like refund.<br />
                        Launching without royalties.<br /><br />

                        When the time is right, the sudoswap pool may be closed and ze Golems will be set free.<br /><br />

                        <div className="smallfont">
                            P.S. I call them "ze Golems" because they keep mumbling "WE ARE ZE GOLEMZ"...
                        </div>
                    </p>

                </div>

                {
                    /*<div className="divider"></div>
                <div className="divider"></div>


                <div className="goopapertext">

                    <div className="date">
                        April 7th, 2023, Part one
                    </div>

                    <p>
                        Dear Anon,<br />
                        if you're reading this, it means you found my notes!<br /><br />

                        Here I will tell my story. The story of what I call "ze Golemz"...<br /><br />                       

                        It was in the years 2021-2022 when technologies like 'Necrobotics', 'Stable AI' and 'NFTs' started to arise. Humans had lost all sense of ethics. 
                        We were desperate to find the next innovation to keep the great global economic Ponzi scheme running.<br /><br />

                        It was unbearable. That's why I started working on my time machine.<br />

                        Last month, I finally finished the designs and was able to ignite the machine successfully.<br /><br />

                        In the following letters, I will report what happened...<br /><br />      

                        Donatello       


                    </p>
                </div>

                <div className="divider"></div>

                <div className="goopapertext">

                <div className="date">
                        April 7th, 2023, Part two
                    </div>

                    <p>
                        On December 8th 2022, due to high gas prices, I was finally able to afford enough fuel to start the machine.<br />
                        On this historic first test, I was able to briefly peak into the year 2044.<br />
                        What I saw scared me. <br /><br />

                        Artificial intelligence had taken over.<br />
                        What was left of humanity was frightening: these humans had merged their bodies with technology. Most of them could no longer be considered human. 
                        These people were half tech, half human. They were wearing plastic face masks, glasses, and strange headphones. Some even had deformed faces.<br />
                        There was no human soul left in them.<br />
                        Their minds were controlled by another identity (Maybe an AI Hive Mind?)...<br /><br />
                        Here is a quick sketch:


                    </p>

                    <img src="2044.jpg" className="y2044"/>


                </div>

                <div className="divider"></div>

                <div className="goopapertext">

                    <div className="date">
                        April 7th, 2023, Part three
                    </div>

                    <p>
                        
                        Due to my low fuel level, I had to return quickly.<br />
                        Once I was back, I immediately knew I had to do something about this. <br />
                        I had to protect the world from its own future. I had to protect humanity from becoming one with their technological overlords.<br /><br />
                        That's when I started my work on ze Golemz... <br /><br />
                        I knew we could only win this final fight, this coming AIpocalypse, if we approached things on serious and truly epical proportions.<br /><br />

                        I remembered two old human Stories.<br />
                        The first was the story of Adam and Eve. God made Adam, the first real Golem, from mud, and endowed him with life.<br />
                        The second story was about 300 strong men that protected Greece from the invasion of the persian ruler Xerxes.<br /><br />
                        Our predicament was a combination of the two. Preventing the end of mankind. <br />
                        I needed 300 strong men. But dear anon, in the year 2022, real men were very hard to find...<br />
                        So I had to make my own...<br /><br />
                        I made ze Golemz.<br /><br />

                        <div className="smallfont">
                            (P.S. I keep saying 'ze' because that's how they referred to themselves. They kept shouting 'WE ARE ZE GOLEMZ')
                        </div>
                        


                    </p>
                </div>

                <div className="goopapertext">

                    <div className="date">
                        April 7th, 2023, Part two
                    </div>

                    <p>
                        
                        Once I returned to the present time, I knew I had to do something about this. <br />
                        I had to protect the world from its own future. I had to protect humanity from becoming one with their technological overlords.<br /><br />
                        That's when I started my work on ze Golemz... <br /><br />
                        I knew we could only win this final fight, this AIpocalypse, if we approached it on serious and truly epical proportions.<br /><br />
                        I remembered two old human Stories.<br />
                        The first was the story of Adam and Eve. God made Adam, the first real Golem, from mud, and endowed him with life.<br />
                        The second story was about 300 strong men that protected Greece from the invasion of the persian ruler Xerxes.<br /><br />
                        Our predicament was a combination of the two. Preventing the end of mankind. <br />
                        I needed 300 strong men. But dear anon, in the year 2022, real men were very hard to find...<br />
                        So I had to make my own...<br /><br />
                        I made ze Golemz.<br /><br />

                        <div className="smallfont">
                            (P.S. I keep saying 'ze' because that's how they referred to themselves. They kept shouting 'WE ARE ZE GOLEMZ')
                        </div>

                    </p>
                </div>

                <div className="goopapertext">

                    <p>

                        Just like their biblical ancestors, ze Golemz are <br />
                        "artificial beings endowed with life".<br /><br />

                        I created them to help me survive<br />
                        <h3 >the AIpocalypse.</h3><br />

                        Our God made Adam, the first Golem, from mud.<br />
                        I made ze Golemz from data. <br /><br /><br />                    
                        
                        I made<br /><br />
                        <h2>300 Golemz</h2>
                        <span className="smallfont">as NFTs on a custom ERC721A contract.</span><br/><br/>

                        But...<br /><br />
                        I need your help! <br /><br />

                        I was afraid of their power.<br />
                        I had to put them into boxes. <br /><br />


                        deployed all of them into a Sudoswap pool.<br /><br />

                        Like buying the cat in the bag,<br />
                        you can buy ze Golem in ze Box.<br /><br />

                        Every Golem consists of 3 parts:<br />
                        ze Goo, ze Tech & ze Golem<br /><br />

                        Just like humans, all Golemz were born equal.<br />
                        But some Golemz are more equal than others.<br /><br />

                        To proof that they're alive,<br />
                        ze Golemz have Identity Cards.<br /><br/>

                        Once you have claimed ownership over a Golem,<br />
                        you will be able to create an Identity card for your Golem.<br /><br />

                        Once enough Golemz have their Identity Cards, <br />
                        the story will continue...<br />

                    </p>

                </div>

                <div className="divider"></div>
                <div className="divider"></div> */ }
                <div className="divider"></div> 

                <p className="whomadezegolemz">ze golemz by <a href="https://twitter.com/donbuidl0r" target="_blank">donatell0</a></p>
               
            </div>
        </div>
    );
};

export default Story;
