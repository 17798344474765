import { useEffect, useState, useRef } from "react";

import {
    getSudoPoolPrice
} from "./utils/interact.js";

const Story = (props) => {

    const [poolprice, setPoolPrice] = useState(false);

    useEffect(() => {

        const caller = async () => {
            let price = await getSudoPoolPrice();
            setPoolPrice(price); 
            
        };
        caller().then(() => {

        });        

    }, []);


    return (
        <div>
            <div className="golemcontainer">

                <div className="tiltleft">
                    <h2>"WTF iz a Golemz?"</h2>
                    <p>We are ze Golemz!</p>
                    <p className="smallfont">"Ze Golemz" is an interactive Web3 experience with a gud Story.<br />
                    It is controlled by Golemz NFTs.</p>
                </div>


                <div className="divider"></div>
                <div className="tiltright">
                    <h2>"Who ownz ze Golemz?"</h2>
                    <p>We own ze Golemz!</p>
                    <p className="smallfont">NFT holders hold commerical rights over their Golemz.<br />
                    Their Golemz are the stars of ze Story.</p>                
                    { /* <a className="bigsudobutton" href="/" target="_blank">All revealed Golemz</a> */ }
                </div>


                <div className="divider"></div>
                <div className="tiltleft">
                    <h2>"Where can I get a Golemz?"</h2>
                    <p>All Golemz can be claimed through Sudoswap.xyz<br />where they're chilling in their pool.</p>
                    { /* <a className="bigsudobutton" href="https://sudoswap.xyz" target="_blank">Ze official pool</a> */ }
                   <p><a href="https://sudoswap.xyz/#/swap/0x43d1262197ae2d0a6a968343c4478b662c3cdfe3" target="_blank">Sudoswap pool here</a></p>
                </div>


                <div className="divider"></div>
                <div className="tiltright">
                    <h2>"What are ze Golemz wörz?"</h2>
                    <p>Ze Golemz are currently wörz: {poolprice}Ξ!</p>
                    <p className="smallfont">Price per Golemz changes with delta 5% each swap.</p>
                    <p className="smallfont">Get a Golemz at your own risk,</p>
                    <p className="smallfont">but be aware that you may need to chug Tampup if you don't have one.</p>
                </div>
                
                <div className="divider"></div>
                <h2>Ze Golemz Story</h2>
                <p><a href="https://twitter.com/zegolemz" target="_blank">Follow ze Golemz</a></p>

                <div className="divider"></div>
                <h2>Own a Golemz?</h2>
                <p><a href="https://discord.gg/WNcNpfuQEY" target="_blank">Lurk with other Golemz</a></p>

                <div className="divider"></div>
                <h2>More Questionz?</h2>
                <p><a href="/Goopaper">Read ze Goopaper</a></p>

                <div className="divider"></div>

                <p>
                    There is only one official<br />
                    <a href="https://etherscan.io/address/0x6503bf993a1529314337f357847b1b042f85e116" target="_blank">Contract on Etherscan</a>
                </p>

                <div className="divider"></div>

                <p>
                    We also have some complicated<br />
                    <a href="/TermsOfService" >Terms of Service</a>
                </p>

                <div className="divider"></div>
                <div className="divider"></div>
                <div className="divider"></div>

                <p className="whomadezegolemz">ze golemz by <a href="https://twitter.com/donbuidl0r" target="_blank">donatell0</a></p>
               
            </div>
        </div>
    );
};

export default Story;
