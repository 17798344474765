import {
    setGolemzData
} from "./utils/apicalls.js";

import { useEffect, useState } from "react";


const APIdata = (props) => {

    const [data, setData] = useState(0);

    useEffect(() => {

        const caller = async () => {
            await setGolemzData();
            setData(1);
        };


        caller().then(() => {});


    }, []);   


    return (
        <div>
            {data}
        </div>
    );
};

export default APIdata;
