
import "./App.css";
import Header from "./Header";
import Story from "./Story";


import { useEffect, useState } from "react";

const App = (props) => {
    useEffect(() => {
        const caller = async () => {};
        caller().then(() => {});


        return () => {};
    }, []);

    return (
            <div className="App">
                {
                    <div>
                        <Header />
                        <Story />
                    </div>
                }
            </div>
    );

};


export default App;
