import { useEffect, useState, useRef } from "react";


const AGudStory = (props) => {

        
    return (
        <div className="wrapagudstory">
            <br />
            <h2>Read ze "A Gud Story"</h2>  

                <a href="https://twitter.com/zegolemz" target="_blank">
                    Follow ze Story on Bird App
                </a>
                <br /><br />
                <a href="/"><div className="button leavebuttonblack">
                    Close
                </div>
            </a>
            <br /><br />

            <h3>Prologue</h3>

            <a href="/AGudStory/2" className="button leavebuttonblack">
                Next >
            </a>
            
            <div className="agudchapter">

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Prologue/Intro.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/Intro2.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                       Ze Story begins silently on a fictive April 7th, 2023 <br />
                       in Donatell0's office.
                    </div>
                    <img loading="lazy" src="/AGudStory/Prologue/1.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/2.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/3.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/4.jpg" className="agudimgsmall"></img>
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        1
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Prologue/5.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/6.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/7.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/8.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/9.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/10.jpg" className="agudimgsmall"></img>

                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        2
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Prologue/11.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/12.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Anon passes Donatell0: "Ze gud Ketschup".<br />
                        Donatell0 is happy.
                    </div>
                    <img loading="lazy" src="/AGudStory/Prologue/12A.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/13.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/14.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/15.jpg" className="agudimgsmall"></img>                    
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        3
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Prologue/16.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/17.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Anon finds ze Cluez: "Ze Gomakoooor is gone"!
                    </div>
                    <img loading="lazy" src="/AGudStory/Prologue/18.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/19.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/20.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/21.jpg" className="agudimgsmall"></img>
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        4
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Prologue/22.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/23.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Anon solves ze Riddle: "ze Code".<br />
                        Ze first Copper is found!
                    </div>
                    <img loading="lazy" src="/AGudStory/Prologue/24.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/25.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/26.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/27.jpg" className="agudimgsmall"></img>
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        5
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Prologue/28.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/29.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/30.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/31.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/32.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/33.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Anon heeeelps: "Erit Lux - We need ze light". <br />
                        The journey can continue.
                    </div>
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        6
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Prologue/34.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/35.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/36.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/37.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Anon is struggling with Bozz Battle...<br />
                        Donatell0 gives anon ze "A gud Story" book and tells a short origin Story... <br />
                        <a href="https://twitter.com/donbuidl0r/status/1564292648902574080" target="_blank">Read the short origin Story on Bird App</a>
                    </div>
                    <img loading="lazy" src="/AGudStory/Prologue/38.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/38B.jpg" className="agudimgsmall"></img>                       
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        7
                    </div>
                </div>

                <div className="agudchapterinside">
                    <div className="activation">
                        Anon has failed the deadline.<br />
                        Donatell0 loses his patience.
                    </div>
                    <img loading="lazy" src="/AGudStory/Prologue/39.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/40.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/41.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/42.jpg" className="agudimgsmall"></img>
                   
                    <img loading="lazy" src="/AGudStory/Prologue/43.jpg" className="agudimg"></img>
                
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        8
                    </div>
                </div>

                <div className="agudchapterinside">
                    <div className="activation">
                        A consequence of anon's failure is triggered.<br />
                        A few hours later Donatell0 returns with a red crowbar. <br />
                        A better weapon would have been nice...
                    </div>
                    <img loading="lazy" src="/AGudStory/Prologue/44.jpg" className="agudimg"></img>

                    <img loading="lazy" src="/AGudStory/Prologue/45.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Prologue/46.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Anon heeeelps and "Breaks ze goddamn door!!!"
                    </div>
                    <img loading="lazy" src="/AGudStory/Prologue/47.jpg" className="agudimgsmall"></img>                   
                    <img loading="lazy" src="/AGudStory/Prologue/48.jpg" className="agudimgsmall"></img>
                
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        9
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Prologue/49.jpg" className="agudimg"></img>                    
                    <img loading="lazy" src="/AGudStory/Prologue/50.jpg" className="agudimg"></img>
                    <div className="activation">
                        Community unlocks: "ze Pool Club".<br />
                        The End of the Prologue is reached.
                    </div>
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        10
                    </div>
                </div>                

            </div>

            <h3>Summary of Prologue</h3>

            <div className="wrapsummary">

                <div className="summaryblock">

                    <img loading="lazy" src="/goomakoooor9001.png" className="summaryitem"></img><br />

                    <div className="summaryitemline">Goomakoooor9001 makes Golemz</div>
                    
                </div>
                
                <div className="summaryblock">
                    <img loading="lazy" src="/Tampupbottle.png" className="summaryitem"></img><br />

                    <div className="summaryitemline">Donatell0 doesn't like ze Tampup</div>
                </div>

                <div className="summaryblock">
                    <img loading="lazy" src="/BigTeck.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Donatell0's enemy is "Big Teck"</div>
                </div>

                <div className="summaryblock">
                    <img loading="lazy" loading="lazy" src="/AGudStory/Prologue/14.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">"Big Teck" stole ze Goomakoooor9001</div>
                </div>

                <div className="summaryblock">
                    <img loading="lazy" loading="lazy" src="/AGudStory/Prologue/51.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Ze Golemz live in ze Pool Club</div>
                </div>

                <div className="summaryblock">
                    <img loading="lazy" loading="lazy" src="/item/weapon_crowbar.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">The red crowbar was unlocked</div>
                </div>

                <div className="clearfix"></div>

            </div>
            
            <br /><br />
            <h3>To be continued...</h3>

            <br />
            <a href="https://sudoswap.xyz/#/browse/buy/0x6503bf993a1529314337f357847b1B042f85E116" target="_blank">
                Get a Golemz and become part of ze Story
            </a><br /><br />
            <a href="https://twitter.com/zegolemz" target="_blank">
                Follow ze Story on Bird App
            </a>
            
        </div>
    );
};

export default AGudStory;
