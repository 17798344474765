import { useEffect, useState, useRef, createContext, useContext } from "react";

import {
    connectWallet,
    disconnectWallet,
    getCurrentWalletConnected,
    setWa,
    getWa,
    getGolemz,
    getGolemzList,
} from "../utils/interact.js";


export const UserContext = createContext({
    walletAddress: null,
    setWalletAddress: () => null,
    status: null,
    setStatus: () => null,
    loginError: null,
    setLoginError: () => null,
    golemz: null,
    setGolemz: () => null,
    golemzDetails: null,
    setGolemzDetails: () => null,
    effect: null,
    setEffect: () => null,
    selectedGolemz: null,
    setSelectedGolemz: () => null,
    saveAndSelectGolemz: () => null,
    getSelectedGolemz: () => null,
    setDefaultGolemz: () => null,
    canMenu: null,
    setCanMenu: () => null,
    allowMenu: () => null,
    blockMenu: () => null,
    defaultchar: null,
    setCursor: () => null,
    canMoveWithKeys: null,
    setCanMoveWithKeys: () => null,
    currentRoom: null,
    setCurrentRoom: () => null,
    getCurrentRoom: () => null,
});


export const UserProvider = ({children}) => {

    const [walletAddress, setWalletAddress] = useState("");
    const [status, setStatus] = useState("");
    const [loginError, setLoginError] = useState(false);

    const [golemz, setGolemz] = useState(-1);
    const [golemzDetails, setGolemzDetails] = useState(false);

    const defaultEffect = "Nothing happens";
    const [effect, setEffect] = useState(defaultEffect);

    const [mode, setMode] = useState('move');
    const [item, setItem] = useState('no item');

    const defaultchar = "/item/characterselection.jpg";
    
    const [canMenu, setCanMenu] = useState(true);

    const [canMoveWithKeys, setCanMoveWithKeys] = useState(true);

    let currentRoom;

    const [selectedGolemz, setSelectedGolemz] = useState({
        name: "Select Golemz",
        id: -1,
        img: defaultchar
    });

    useEffect(() => {

        const caller = async () => {
            const { address, status } = await getCurrentWalletConnected();
            initWallet(address);
            addWalletListener();
            allowMenu();

            if(getSelectedGolemz() === "undefined")
            {          
                let sG = {
                    name: "Select Golemz",
                    id: -1,
                    img: defaultchar
                }

                setSelectedGolemz(sG);
            }
            else
            {
                setSelectedGolemz(getSelectedGolemz());
            }

            setCursor();

            
        };
        caller().then(() => {
        });        

    }, []);

    useEffect(() => {

        const caller = async () => {
            setCursor();            
        };
        caller().then(() => {
        });        

    }, [selectedGolemz, setSelectedGolemz]);

    useEffect(() => {

        console.log(window?.localStorage);   

    }, [window?.localStorage, window?.localStorage.setItem, currentRoom]);

    const setCursor = () => {
        let sg = getSelectedGolemz();

        //FUCKING BLACK MAGIC FFS
        window.setTimeout(() => {
            if(sg !== "undefined" && sg?.id !== -1 )
            {     
                let wrappoolclub = document.getElementsByClassName("poolclubzonelarge");
                for (let i = 0; i < wrappoolclub.length; i++) {
                    wrappoolclub[i]?.classList.add("golemhand");
                }

                let wrappoolclub2 = document.getElementsByClassName("poolclubzone");
                for (let i = 0; i < wrappoolclub.length; i++) {
                    wrappoolclub2[i]?.classList.add("golemhand");
                }
    
            }
            else
            {
                let wrappoolclub = document.getElementsByClassName("poolclubzonelarge");
                for (let i = 0; i < wrappoolclub.length; i++) {
                    wrappoolclub[i]?.classList.remove("golemhand");
                }

                let wrappoolclub2 = document.getElementsByClassName("poolclubzone");
                for (let i = 0; i < wrappoolclub.length; i++) {
                    wrappoolclub2[i]?.classList.remove("golemhand");
                }
            }
        },300);

        
    }

    const addWalletListener = () => {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    initWallet(accounts[0]);
                    setDefaultGolemz();
                } else {
                    initWallet("");
                }
            });

            window.ethereum.on("disconnect", () => {
                initWallet("");
                alert("disconnected");
            });
        } else {
        }
    }

    const initWallet = (address) => {
        setWalletAddress(address);
        setGolemzForWallet(address);

        let selectedGolemz = getSelectedGolemz();

        //Check if the selected Golemz is still in wallet after wallet change
        let found = false;
        if(golemzDetails.ownedNfts !== undefined)
        {
            for(let i = 0; i < golemzDetails.ownedNfts.length; i++)
            {
                if(selectedGolemz.id === parseInt(golemzDetails.ownedNfts[i].id.tokenid))
                {
                    found = true;
                }
            }            
        }
        
        if(!found)
        {
            setDefaultGolemz();
        }
        

    }

    const setGolemzForWallet = async (address) => {
        let gz = await getGolemz(address);
        setGolemz(gz);

        let gDs = await getGolemzList(address);
        setGolemzDetails(gDs);
    }

    const saveAndSelectGolemz = (user,e) => {


        if(e?.id !== "undefined" && e !== null)
        {
            e.imgtrans = "/golemz/g"+e?.id+".png";
        }
        else   
        {      
            e = {};
            e.imgtrans = "";
            e.id = -1;
            e.name = "Select Golemz";
            e.img = user.defaultchar;
        }


        setSelectedGolemz(e);
        window?.localStorage.setItem("selectedGolemz", JSON.stringify({...e}));
    }

    const getSelectedGolemz = () => {
       return JSON.parse(  window?.localStorage.getItem("selectedGolemz")  );
    }

    const setDefaultGolemz = () => {
        let sG = {
            name: "Select Golemz",
            id: -1,
            img: defaultchar
        }

        setSelectedGolemz(sG);
    }

    const blockMenu = () => {
        setCanMenu(false);
    }

    const allowMenu = () => {
        setCanMenu(true);
    }

    const setCurrentRoom = (val) => {
        window?.localStorage.setItem("currentRoom", val);
        currentRoom = val;
    }

    const getCurrentRoom = () => {
        return window?.localStorage.getItem("currentRoom");
    }

    
    return (
        <UserContext.Provider
                value={{
                    currentRoom, getCurrentRoom, setCurrentRoom, canMoveWithKeys, setCanMoveWithKeys, setCursor, defaultchar, canMenu, blockMenu, allowMenu, setDefaultGolemz, walletAddress, setWalletAddress, golemz, setGolemz, status, setStatus, loginError, setLoginError, golemzDetails, setGolemzDetails, effect, setEffect, mode, setMode, item, setItem, selectedGolemz, setSelectedGolemz, saveAndSelectGolemz, getSelectedGolemz
                }}
        >
            {children}
        </UserContext.Provider>

    );
};


export const useUser = () => useContext(UserContext)