import { useEffect, useState, useRef } from "react";

import axios from "axios";

import {
    triggerSuccessfulEffect,
    triggerUnsuccessfulEffect
} from "./actions.js";

import { useUser } from "./User";
import {TextBox } from "./TextBox";
import { setWa, waitForGolemzNamed } from "../utils/interact.js";

import {
    Link
} from "react-router-dom";

import { useNavigate } from 'react-router-dom';

/*
ABOUT THIS ROOM:
Buck and Anon are taking a shower.
Ze Golemz are named.
*/

const RoomCafeteria = (props) => {

    let user = useUser();

    useEffect(() => {

        loadGuestbookEntries();      

    }, []);
    

    useEffect(() => {

        user.setCursor();
        user.setCurrentRoom("Cafeteria");

        window.addEventListener('keydown', navigateWithKeys);

        return () => { window.removeEventListener('keydown', navigateWithKeys) ;}        
      

    }, [user]);
   

    /* GAME LOGIC */

    const Buck = useRef();
    const Guestbook= useRef();
    const sitGolemzRef = useRef();

    const navigate = useNavigate();

    const specialaction = useRef();
    const poolclubzone = useRef();

    const [buckIsGone, setBuckIsGone] = useState(false);

    const [guestbookAmount, setGuestbookAmount] = useState("");
    const [guestbookGolemz, setGuestbookGolemz] = useState([]);
    const [sitGolemzPath, setSitGolemzPath] = useState("");


    let ginshower;

    const [audioWa] = useState(new Audio("/golemzOG.wav"));
    const [schrubschrub] = useState(new Audio("/sounds/schrubschrub.mp3"));

    const resetRoom = () => {
        setBuckIsGone(false);
        resetSpeechBubbles();        
        Buck.current.classList.remove("movecharoutbottom");

        resetSpeech();
    }

    const resetSpeech = () => {
        chooseText("","A0","happy"); 
        chooseText("","A1","happy");     
        chooseText("","A2","happy"); 
        chooseText("","A3","happy"); 
        chooseText("","A4","happy"); 
        chooseText("","beertext","happy"); 
        chooseText("","sittext","happy"); 
        chooseText("","ketschuptext","happy"); 
    }

    const checkAction = (e) => {

        unsetActions();

        if(e === "guestbook")
        {
            if(user.mode === "inspect")
            {
                doInspect("Buck's big book.");
            }else if(user.mode === "use" || user.mode === "open" || user.mode === "pull" || user.mode === "pickup")
            {
                openGuestbook();
            } 
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }

        } 
        else if(e === "zeopenguestbook")
        {
            if(user.mode === "inspect")
            {
                doInspect("Ze open Guestbook");
            }   
            else if(user.mode === "close")
            {
                closeGuestbook();               
            } 
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }

        } 
        else if(e === "beer")
        {
            if(user.mode === "inspect")
            {
                doInspect("A beer tap... It's currently empty.");
            }  
            else if(user.mode === "use" || user.mode === "pull" || user.mode === "open")
            {
                triggerBeer();
            } 
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            } 

        }
        else if(e === "chairs")
        {
            if(user.mode === "inspect")
            {
                doInspect("Sometimes Golemz hang out here...");
            }  
            else if(user.mode === "use" || user.mode === "pull")
            {
                sitGolemz();
            }  
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        else if(e === "shelf")
        {
            if(user.mode === "inspect")
            {
                doInspect("The shelf is empty...");
            }  
        }
        else if(e === "logo")
        {
            if(user.mode === "inspect")
            {
                doInspect("'Only ze gud coffee for your bucks' in Buck's Cafeteria.");
            }  
        }
        else if(e === "cash")
        {
            if(user.mode === "inspect")
            {
                doInspect("A broken Cash register...");
            }  
        }
        else if(e === "checkBuck")
        {                
            if(user.mode === "inspect")
            {
                doInspect("A Golemz named 'Buck'");
            } 
            else if(user.mode === "give" && user.item ==="zegudketschup")            
            {
                resetSpeech();
                chooseText("ketschuptext","","happy"); 
            }
            else if(user.mode === "talk")
            {
                resetSpeech();
                chooseText("A0","","happy");                
            } 
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }

        }
        else{
            triggerUnsuccessfulEffect(user.setEffect);
        }
        
    }



    const resetSpeechBubbles = () => {
        /*clickBox.current.classList.remove("show");
        clickBox.current.classList.remove("show");
        checkBuck.current.classList.remove("show");
        Buck.current.classList.remove("schrubschrub");
        schrubbing.current.classList.remove("show");
        clickKetschup.current.classList.remove("show");
        pickupKetschup.current.classList.remove("show");
        talkBuck.current.classList.remove("show");*/
    }

    const checkActionMD = (e) => {

        /*if (e=== "schrubSchrub")
        {
            if(user.mode === "use")
            {
                resetSpeechBubbles();  
                schrubschrub.play();
                checkBuck.current.classList.remove("show");	
                Buck.current.classList.add("schrubschrub");
                schrubbing.current.classList.add("show");

            }
        }*/
    }

    const checkActionMU = (e) => {

        /*if (e === "schrubSchrub")
        {
            if(user.mode === "use")
            {
                resetSpeechBubbles();  
                schrubschrub.pause();
            }	           
        }*/
    }

    const talkTo = (char) => {

    }

    const unsetActions = () =>
    {

    }

    const triggerBeer = () => {
        resetSpeech();

        chooseText("beertext","","happy");   
    }

    const sitGolemz = () => {
        resetSpeech();

        sitGolemzRef.current.classList.remove("movecharinfrombottom");

        chooseText("sittext","","happy");  

        sitGolemzRef.current.classList.add("movecharinfrombottom");
        
        setSitGolemzPath(user.selectedGolemz?.imgtrans);

    }

    const doInspect = (e) => {
        triggerSuccessfulEffect(e,user.setEffect);
    }

    const doStep = (e,eToRemove) =>{

        if(e !== "")
            e.current.classList.add("show");

        if(eToRemove !== "")
            eToRemove.current.classList.remove("show");
        
    }

    const chooseText = (e,eToRemove,h) => {

        if(e === undefined)
            e = "";

        if(eToRemove === undefined)
            eToRemove = "";

        let i;
        let r;

        if(e !== "")
            i = document.getElementById(e); //show

        if(eToRemove !=="" )
            r = document.getElementById(eToRemove); //hide

        if(i !== null && i !== undefined)
            i.classList.remove("hidden");

        if(r !== null && r !== undefined)
            r.classList.add("hidden"); 

        if(eToRemove === "all")
        {            
            let textboxes = document.querySelectorAll(".wraptextBubble");

            textboxes.forEach(box => {
                box.classList.add("hidden");
            });
        }

        if(h === "happy")
        {
            Buck.current.classList.add("happy");
        }
        else if(h === "unhappy")
        {
            Buck.current.classList.add("unhappy");
        }

        /* SPECIAL CONDITIONS */
        if(eToRemove === "A7")
        {
            setBuckIsGone(false);
            Buck.current.classList.add("movecharoutbottom");
            specialaction.current.classList.remove("hidden");
            specialaction.current.classList.add("fadein");
        }
    }



    const openGuestbook = () => {

        user.setCanMoveWithKeys(false);

        Guestbook.current.classList.remove("guestbookhidden");     

        resetSpeech();
    }

    const closeGuestbook = () => {
        user.setCanMoveWithKeys(true);
        Guestbook.current.classList.add("guestbookhidden");    
    }

    const loadGuestbookEntries = async () => {

        let haveNames = [];

        try{
            let config = {
                method: "get",
                url: `https://strapi.golemz.wtf/api/golemzs?sort=tokenId&pagination[start]=0&pagination[limit]=300&filters[name][$ne]=`,
    
            };
    
            await axios(config)
            // eslint-disable-next-line no-loop-func
            .then(async (response) => {

                let data = response.data.data;
                
                for(let i = 0; i<data.length; i++)
                {
                    if(data[i].attributes.name !== "")
                    {
                        
                        haveNames.push(data[i]);
                    }
                }

            });
        }catch(error)
        {
            console.log("ERROR GETTING GOLEMZ",error);
        }

        setGuestbookGolemz(haveNames);
        

        setGuestbookAmount(haveNames.length);
    }




    const goTo = (roomUrl) => {
        navigate(roomUrl);

        /*poolclubzone.current.classList.add("blurout3");
        window.setTimeout(() => {}, 3000);    */    

    }

    const navigateWithKeys = (event) => {

        if(user.canMoveWithKeys)
        {
            switch( event.keyCode ) {
                case 65:
                    goTo("/PoolClub/3");
                    break;
                default: 
                    break;
            }
        }

    }

    
    return (
        <div>

            { user.golemz > 0 ? (
                <div >
                    <div className="wrappoolclub wrappoolclublarge blurinroom">    
                    
                            <div className="poolclubzonelarge" ref={poolclubzone}>    

                                <div className="wraptextBubble wraptextBubbleLeft" id="A0">
                                    
                                    <div className="text">
                                        G(ole)m Degen! Welcome to my Cafeteria! <br />
                                        
                                        Usually I serve ze gud Coffee here, but I don't have anything to sell right now.<br />
                                        My cash register broke during ze gud Ketschup mess...                     
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A1","A0","happy")} }>
                                        >How unlucky! But nice to meet you, Buck!
                                    </div>

                                </div>       

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A1">
                                    
                                    <div className="text">
                                        Anyways, here in my Cafeteria, ze Golemz hang out after they have finished their Shower.<br />
                                        I know them all and I keep track of them in my Guestbook...
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A2","A1","happy")} }>
                                        >Guestbook?
                                    </div>

                                </div> 

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A2">
                                    
                                    <div className="text">
                                        Yes! I write it all down by hand. Anything happens? Buck will know! <br />
                                        Here take a look by youself...
                                    </div>

                                    <div className="choicebutton" onClick={ () => {openGuestbook()} }>
                                        >Show me ze Guestbook!
                                    </div>

                                    <div className="choicebutton" onClick={ () => chooseText("A3","A2","happy")} >
                                        >Are there any other news?
                                    </div>

                                </div>    

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A3">
                                    
                                <div className="text">
                                        So, I heard ze Golemz were all getting ready for their first mission right now.<br />
                                        Apparently they will soon take their Mugshot and decide on Mission #1...
                                    </div>

                                    <div className="choicebutton" onClick={ () => chooseText("A4","A3","happy") }>
                                        >Hah, thanks Buck!
                                    </div>

                                </div>   

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A4">
                                    
                                    <div className="text">
                                        You're welcome...<br />
                                        So until my stock is refilled and the cash register repaired, all I can offer you is to browse through ze Guestbook...
                                    </div>

                                    <div className="choicebutton" onClick={ () => {openGuestbook()} }>
                                        >Show me ze Guestbook!
                                    </div>

                                </div>   

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="beertext">
                                    
                                    <div className="text">
                                        Hey stop that! I told you I'm out of everything and I can't trade right now!<br />
                                        I need my Ze Tokenz from the machine back first...
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("","beertext","happy")} }>
                                        >Whoops, sorry!
                                    </div>

                                </div>   

                                
                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="ketschuptext">
                                    
                                    <div className="text">
                                        Awww, that's cute Degen, but I'm fine.<br />
                                        As soon as the Cash register works again, I can sell you more of ze gud Ketschup!
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("","ketschuptext","happy")} }>
                                        >Okay... Then I'll keep my ketschup!
                                    </div>

                                </div>   

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="sittext">
                                    
                                    <div className="text">
                                        Look, I'm really sorry, but I'm out of every last drop...<br />
                                        I don't even have ze gud Ketschup left.<br />
                                        Please come back later if you want to have a drink.
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("","sittext","happy")} }>
                                        >Okay...
                                    </div>

                                </div>  


                                <div className="guestbook guestbookhidden" ref={Guestbook} onClick={ () => checkAction("zeopenguestbook")}>

                                    <div className="guestbookleft">
                                        
                                    </div>

                                    <div className="guestbookright">
                                        <h2>ze { guestbookAmount } Golemz in ze Guestbook</h2>

                                        <div className="guestbookentries">
                                            {guestbookGolemz?.map((item,i) => (
                                                <div className="golemGuestbook" key={"Golemz_"+parseInt(item.attributes.tokenId)} id={"Golemz_"+parseInt(item.attributes.tokenId)} >                                                        

                                                        {item.name !== "" && (
                                                            <div className="wrapgolem">      
                                                                <img  loading="lazy" src={"/golemz/thumb_colored/g"+item.attributes.tokenId+".png"} className="left golemimg"/>      

                                                                <div className="text">

                                                                    <h2>
                                                                        { item.attributes.name }
                                                                    </h2>
                                                                    <div>
                                                                        <a target="_blank" href={"https://looksrare.org/collections/0x6503bf993a1529314337f357847b1B042f85E116/"+item.attributes.tokenId}>
                                                                            Golemz #{ item.attributes.tokenId }
                                                                        </a>
                                                                    </div>
                                                                
                                                                    <div className="aboutline">
                                                                        <h3>About</h3>

                                                                        {item.attributes.lore !== "" && item.attributes.lore !== null ? (
                                                                            <div className="loreline">
                                                                                { item.attributes.lore }
                                                                            </div>
                                                                        ) : (
                                                                            <div className="greyfont loreline">
                                                                                I don't know anything about this Golemz yet...
                                                                            </div>
                                                                        )}

                                                                    </div>

                                                                    <div className="copperline">
                                                                        <img src="/copper-small.jpg" className="coppericon"/> { item.attributes.copper }
                                                                    </div>

                                                                </div>

                                                                <div className="clearfix"></div>

                                                            </div>
                                                        )}

                                                    

                                                </div>
                                                
                                            ))}

                                        </div>

                                        <div className="guestbookentry hidden">

                                            {

                                            }

                                        </div>


                                    </div>

                                    <div className="guestbookpage1">
                                    </div>
                                    <div className="guestbookpage2">
                                    </div>
                                    <div className="guestbookpage3">
                                    </div>

                                </div>



                                <div className="guestbookamount" onClick={ () => checkAction("guestbook") } >
                                    {
                                        guestbookAmount
                                    }
                                </div>

                                <div className="sitGolemz" ref={sitGolemzRef}>
                                    <img src={sitGolemzPath} />
                                </div>

                                <div className="cafeteria_beer" onClick={ () => checkAction("beer") } >
                                </div>

                                <div className="cafeteria_shelf" onClick={ () => checkAction("shelf") } >
                                </div>

                                <div className="cafeteria_chairs" onClick={ () => checkAction("chairs") } >
                                </div>

                                <div className="cafeteria_logo" onClick={ () => checkAction("logo") } >
                                </div>

                                <div className="cafeteria_cash" onClick={ () => checkAction("cash") } >
                                </div>


                                <img src="/rooms/cafeteria_l.png" className="cafeteriabg_top" />

                                <img className="buckincafeteriaLeft buckincafeteria" src="/chars/Buck.png" ref={Buck} onClick={() => {checkAction("checkBuck")} }/>      

                                <img src="/rooms/cafeteria_bg.jpg" className="cafeteriabg_bottom" />

                                <div className="navigation navleft">                                    
                                        <div className="navbutton" onClick={() => {goTo("/PoolClub/3")}}>
                                            A
                                        </div>
                                </div>
                            
                            </div>                          

                    </div>


                    <div className="wrappoolclub nowrappoolclublarge"> 
                         <br />
                        <h2>
                            Degen, your screen is too small to enjoy this room.
                        </h2>
                        <h1>
                            Please switch to a bigger screen.
                        </h1>
                    </div>

                </div>


            ) : user.golemz == 0 ? (
                <div className="wrapmissinggolem">

                    <img src="/MISSINGGOLEM.png" className="missinggolem"/>

                </div>
            ) : (
                <div >
                </div>
            )}
            
        </div>
    );
};

export default RoomCafeteria;
