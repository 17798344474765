
import "./App.css";
import RiddleC from "./RiddleC";


import { useEffect, useState } from "react";

const App = (props) => {
    useEffect(() => {
        const caller = async () => {};
        caller().then(() => {});


        return () => {};
    }, []);

    return (
        <div className="Goopaper">
            {
                <div>
                    <RiddleC />
                </div>
            }
        </div>
    );

};


export default App;
