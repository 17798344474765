
import "./App.css";
import PoolClubMenu from "./poolclub/PoolClubMenu";
import RoomPoolClub from "./poolclub/RoomPoolClub";
import RoomPoolClub2 from "./poolclub/RoomPoolClub2";
import RoomPoolClub3 from "./poolclub/RoomPoolClub3";
import RoomShower from "./poolclub/RoomShower";
import RoomCafeteria from "./poolclub/RoomCafeteria";
import RoomShop from "./poolclub/RoomShop";



import { useEffect, useState} from "react";

import {useLocation} from 'react-router-dom';



const App = (props) => {

    const [path, setPath] = useState("");

  // 👇️ with React router
  const location = useLocation();

    useEffect(() => {
        const caller = async () => {
            setPath(location.pathname.toLowerCase());
        };

        caller().then(() => {
            setPath(location.pathname.toLowerCase());
        });

        return () => {
        };
    }, [location]);

    return (
            <div className="poolclub">
                {
                    <div> 
                        {path === "/poolclub" ? (
                            <RoomPoolClub />
                        ) : path === "/poolclub/2"  ? (
                            <RoomPoolClub2 />
                        ) : path === "/poolclub/3"  ? (
                            <RoomPoolClub3 />                        
                        ) : path === "/shower"  ? (
                            <RoomShower />
                        ) : path === "/cafeteria"  ? (
                            <RoomCafeteria />
                        ) : path === "/shop"  ? (
                            <RoomShop />
                        ) : (                           
                            <div >
                            </div>                            
                        )}                            

                        <PoolClubMenu />
                    </div>
                }
            </div>
    );

};


export default App;
