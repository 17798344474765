import { useEffect, useState, useRef } from "react";

import {
    triggerSuccessfulEffect,
    triggerUnsuccessfulEffect
} from "./actions.js";

import { useUser } from "./User";
import {TextBox } from "./TextBox";
import { setWa, waitForGolemzNamed } from "../utils/interact.js";

import {
    Link
} from "react-router-dom";

import { useNavigate } from 'react-router-dom';

/*
ABOUT THIS ROOM:
Bruv and Anon are taking a shower.
Ze Golemz are named.
*/

const RoomShower = (props) => {

    let user = useUser();

    useEffect(() => {

        user.setCursor();
        user.setCurrentRoom("Shower");

        window.addEventListener('keydown', navigateWithKeys);

        return () => { window.removeEventListener('keydown', navigateWithKeys) ;}
      

    }, [user]);
   

    /* GAME LOGIC */

    const Bruv = useRef();
    const computer = useRef();
    const computerOverlay = useRef();
    const step1 = useRef();
    const step2 = useRef();
    const step3 = useRef();
    const waInput = useRef();
    const showerFog = useRef();
    const navigate = useNavigate();

    const specialaction = useRef();
    const poolclubzone = useRef();

	const [showering, setShowering] = useState(false);
    const [clickComputer, setClickedComputer] = useState(false);
    const [bruvIsGone, setBruvIsGone] = useState(false);
    const [tempGolemzname, setTempGolemzname] = useState("");
    const [golemzShowering, setGolemzShowering] = useState(false);
    const [showerFinished, setShowerFinished] = useState(false);
    const [golemInShower, setGolemInShower] = useState("");
    const [bigGolem, setBigGolemz] = useState("");

    let ginshower;

    const [audioWa] = useState(new Audio("/golemzOG.wav"));
    const [schrubschrub] = useState(new Audio("/sounds/schrubschrub.mp3"));

    const resetRoom = () => {
        setBruvIsGone(false);
        resetSpeechBubbles();        
        Bruv.current.classList.remove("movecharoutbottom");

        setShowerFinished(false);
        closeGomputer();

        specialaction.current.classList.add("hidden");
        specialaction.current.classList.remove("fadein");

        chooseText("A0","","happy");
    }

    const checkAction = (e) => {

        unsetActions();

        if(e === "clickComputer")
        {
            if(user.mode === "inspect")
            {
                doInspect("A Gomputer with a big green button.");
            }   

            if(user.mode === "use" || user.mode === "push" || user.mode === "open")
            {
                setBruvIsGone(true);
                chooseText("A0","all","happy");
                
                Bruv.current.classList.add("movecharoutbottom");  
                
                computer.current.classList.add("grow");
                
                user.setCanMoveWithKeys(false);

                doStep(step1,"");

                window.setTimeout(() => {                     
                    computerOverlay.current.classList.add("fadein");   
                    computerOverlay.current.classList.add("show");                     
                },1000);

                user.setCanMoveWithKeys(false);

                window.setTimeout(() => {
                     
                    computer.current.classList.remove("grow");
                    
                },3000);
            } 

        } 
        else if(e === "clickZeGudKetschup")
        {
            if(user.mode === "inspect")
            {
                doInspect("Every Golemz' favourite shampoo: Ze gud Ketschup.");
            }  
        }
        else if(e === "clickShower")
        {
            if(user.mode === "inspect")
            {
                doInspect("Ze famous shower...");
            }  
        }
        else if(!bruvIsGone)
        {
           
            if(e === "checkBruv")
            {
                if(user.mode === "inspect")
                {
                    doInspect("A Golemz named 'Bruv'");
                } 
            }
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        else if(bruvIsGone)
        {
            if(e === "clickComputer")
            {
                if(user.mode === "inspect")
                {
                    doInspect("A Computer with a big green button.");
                }   


            }      
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        
    }



    const resetSpeechBubbles = () => {
        /*clickBox.current.classList.remove("show");
        clickBox.current.classList.remove("show");
        checkBruv.current.classList.remove("show");
        Bruv.current.classList.remove("schrubschrub");
        schrubbing.current.classList.remove("show");
        clickKetschup.current.classList.remove("show");
        pickupKetschup.current.classList.remove("show");
        talkBruv.current.classList.remove("show");*/
    }

    const checkActionMD = (e) => {

        /*if (e=== "schrubSchrub")
        {
            if(user.mode === "use")
            {
                resetSpeechBubbles();  
                schrubschrub.play();
                checkBruv.current.classList.remove("show");	
                Bruv.current.classList.add("schrubschrub");
                schrubbing.current.classList.add("show");

            }
        }*/
    }

    const checkActionMU = (e) => {

        /*if (e === "schrubSchrub")
        {
            if(user.mode === "use")
            {
                resetSpeechBubbles();  
                schrubschrub.pause();
            }	           
        }*/
    }

    const talkTo = (char) => {

    }

    const unsetActions = () =>
    {

    }

    const doInspect = (e) => {
        triggerSuccessfulEffect(e,user.setEffect);
    }

    const doStep = (e,eToRemove) =>{

        if(e !== "")
            e.current.classList.add("show");

        if(eToRemove !== "")
            eToRemove.current.classList.remove("show");
        
    }

    const closeGomputer = () => {
        computerOverlay.current.classList.remove("show");
        computerOverlay.current.classList.remove("fadein");

        specialaction.current?.classList.add("fadein");       
        specialaction.current.classList.remove("hidden");

        step1.current.classList.remove("show");
        step2.current.classList.remove("show");
        step3.current.classList.remove("show");

        user.setCanMoveWithKeys(true);
    }

    const chooseText = (e,eToRemove,h) => {

        if(e === undefined)
            e = "";

        if(eToRemove === undefined)
            eToRemove = "";

        let i;
        let r;

        if(e !== "")
            i = document.getElementById(e); //show

        if(eToRemove !=="" )
            r = document.getElementById(eToRemove); //hide


        if(i !== null && i !== undefined)
            i.classList.remove("hidden");

        if(r !== null && r !== undefined)
            r.classList.add("hidden"); 

        if(eToRemove === "all")
        {            
            let textboxes = document.querySelectorAll(".wraptextBubble");

            textboxes.forEach(box => {
                box.classList.add("hidden");
            });
        }
        

        if(h === "happy")
        {
            Bruv.current.classList.add("happy");
        }
        else if(h === "unhappy")
        {
            Bruv.current.classList.add("unhappy");
        }


        /* SPECIAL CONDITIONS */
        if(eToRemove === "A7")
        {
            setBruvIsGone(false);
            Bruv.current.classList.add("movecharoutbottom");
            specialaction.current.classList.remove("hidden");
            specialaction.current.classList.add("fadein");
        }
    }

    const initSetWa = (wA,sG,V) => {

        if(sG >= 0)
        {
            setTempGolemzname(V);
        
            doStep(step3,step2);       
        
            trySetWa(wA,sG,V);
        }
        else
        {

        }

    }

    const trySetWa = (wA,sG,V) => 
    {
        setWa(wA,sG,V).then((tx) => {

            if(tx.success)
            {
                setGolemzShowering(true);

                closeGomputer(); 

                golemzWillShower(V);  

                waitForGolemzNamed(tx?.hash, setGolemzShowering, finishShower);
                                             
            }
            else
            {
                doStep(step2,step3);
            }

        });
        
    }

    const golemzWillShower = (name) =>    {        

        showerFog.current.classList.add("show");
        
        setGolemInShower("/golemz/g"+user.selectedGolemz.id+".png");

        user.blockMenu();
    
        user.selectedGolemz.name = name;
        
        specialaction.current.classList.add("hidden");
        specialaction.current.classList.remove("fadein");
        
    }

    const finishShower = () => {       
        setShowerFinished(true);
        setGolemzShowering(false);

        audioWa.play();
        
        user.allowMenu();
    }

    const closeGolemShowered = () => {        

        if(user.selectedGolemz.name !== "")
        {
            Bruv.current.classList.remove("movecharoutbottom");
            chooseText("A6","A0","happy");
        }   

        setShowerFinished(false);

    }

    const goTo = (roomUrl) => {
        navigate(roomUrl);

        /*poolclubzone.current.classList.add("blurout3");
        window.setTimeout(() => {}, 3000);    */    

    }

    const navigateWithKeys = (event) => {

        if(user.canMoveWithKeys)
        {
            switch( event.keyCode ) {
                case 83:
                    goTo("/PoolClub/3");
                    break;
                default: 
                    break;
            }
        }

    }


    
    return (
        <div>

            { user.golemz > 0 ? (
                <div >
                    <div className="wrappoolclub wrappoolclublarge blurinroom">    
                    
                            <div className="poolclubzonelarge" ref={poolclubzone}>

                                <div className="wraptextBubble wraptextBubbleLeft" id="A0">
                                    
                                    <div className="text">
                                        Okay Degen, time to take a shower!<br />
                                        *wwaaaraaAAAaawwrrraAA*
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A1","A0","happy")} }>
                                        >What's up with ze Shower?
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A1">
                                    
                                    <div className="text">
                                        Taking ze Shower is a very important ritual to every Golemz...<br />
                                        It's like being *REBORN*.
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A2","A1","happy")} }>
                                        >Ehm okay?
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A2">
                                    
                                    <div className="text">
                                        When we Golemz take our shower,<br />
                                        it means we are getting ready to go on ze big Adventure! <br />                                
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A3","A2","happy")} }>
                                        >Ze Adventure?
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A3">
                                    
                                    <div className="text">
                                       A real Adventure with Master Donatell0!<br />
                                       During ze Adventure, d0n will challenge us as part of ze Story.<br />
                                       But it's very dangerous and some of us may die!

                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A4","A3","happy")} }>
                                        >That sounds scary!
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A4">
                                    
                                    <div className="text">
                                        It is scary, but it's your choice Degen!<br />
                                        You can sign up your Golemz for ze Adventure<br />by using ze Gomputer here on the right,<br />
                                        or you can simply let us chill savily in ze Pool Club...
                                        
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A5","A4","happy")} }>
                                        >Hmmm, let me see...
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A5">
                                    
                                    <div className="text">
                                        If you're ready to send one of us on ze big Adventure with d0n,<br/>
                                        please select a Golemz in the menu at the bottom and then<br/>
                                        'use' the <span className="green">green button</span> on ze Gomputer!
                                    </div>

                                    <div className="choicebutton red verysmallfont" onClick={ () => {chooseText("A0","A5","happy")} }>
                                        >Hmmm I think you'll have to repeat all of this
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A6">
                                    
                                    <div className="text">
                                        <h2>Well done!</h2>
                                        You have named a Golemz! <br />
                                        You can select and unselect him anytime in the character menu. <br />
                                        The selected Golemz is always the one with which you're currently interacting.
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A7","A6","happy")} }>
                                        >Got it, thanks a lot Bruv!
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A7">
                                    
                                    <div className="text">                                        
                                        I think you're almost ready to go on ze Adventure now Degen. <br />
                                        I will meet you back at ze Pool, but don't hesitate to call me if you need help.
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("","A7","happy")} }>
                                        >Cya later!
                                    </div>

                                </div>



                                <img className="talkingchar talkingcharLeft" src="/chars/Bruv.png" ref={Bruv} onClick={() => {checkAction("checkBruv")} }/> 
                                
                                <div className="showerhead" onClick={() => {checkAction("clickShower")} }></div>
                                <div className="zegudketschupshower" onClick={() => {checkAction("clickZeGudKetschup")} }></div> 
                                
                                <div className="computerWAA" ref={computer} onClick={() => {checkAction("clickComputer")} }>

                                    <img src="/interaction_items/computer.png"  />

                                </div>

                                { golemzShowering && (
                                    <div >
                                        <img className="showerfog" ref={showerFog} src="/zeStory/shower.gif" />

                                        <img className="goleminshower" src={golemInShower} />

                                        <div className="goleminshowerwa">
                                            *wwaaaraaAAAaawwrrraAA*
                                        </div>
                                    </div>
                                ) }

                                {   
                                    (showerFinished && user.selectedGolemz.name !== "") ? (
                                        <div className="wrapbiggolem">

                                            <img className="biggolem" src={golemInShower}  />

                                            <div className="biggolemname">
                                                <h2>G(OLE)M</h2>
                                                <h1>{user.selectedGolemz.name}</h1>
                                                Ready to go on ze Adventure...
                                                <br /><br />
                                                <a className="sharebutton" href={ "https://twitter.com/intent/tweet?text=I%20named%20my%20Golemz%20"+user.selectedGolemz.name+"%20and%20I%20am%20ready%20for%20ze%20Adventure!" } >
                                                    Share on Bird App
                                                </a>

                                                <br /><br />

                                                <a onClick={() => {closeGolemShowered()}} >
                                                    Close
                                                </a>
                                            </div>

                                        </div>
                                    ) : showerFinished && (
                                        <div className="wrapbiggolem">

                                            <img className="biggolem" src={golemInShower}  />

                                            <div className="biggolemname">
                                                <h2>G(OLE)N</h2>
                                                <h1>Golemz #{user.selectedGolemz.id}</h1>
                                                Removed from ze big Adventure...
                                                <br /><br />
                                                Ze Golem will be missed!
                                                <br />
                                                <br />

                                                <a onClick={() => {closeGolemShowered()}} >
                                                    Close
                                                </a>
                                            </div>

                                        </div>
                                    )
                                }
                                

                                <div className="computeroverlay" ref={computerOverlay}>

                                    <div className="step1 step" ref={step1}>
                                        <h2>setWwaaaraaAAAaawwrrraAA()</h2>
                                        <h1>Please read carefully</h1>

                                        So you're ready to go on ze big Adventure?<br />
                                        If so, it's extremely important that you understand <a href="/TermsOfService" target="_blank">ze Terms and Service</a> of ze Golemz.<br />
                                        Please read them.<br /><br />
                                        Ze Gomputer allows you to name your Golemz by interacting with the "setWwaaaraaAAAaawwrrraAA()" function of the GOLEMZ (GMZ) smart contract. This function will name your Golemz and its name will be used in ze Story.<br />
                                        Naming a Golemz means signing it up for ze Story.<br /><br />
                                        Ze Story is interactive.<br />
                                        Golemz may die during ze Story if you do not react to your challenges in time correctly. <br /><br />
                                        This means that
                                        <h2>you may lose your NFT!</h2>

                                        <br />

                                        <div className="computerbutton" onClick={() => doStep(step2, step1)}>
                                            I understand ze Risks
                                        </div>

                                    </div>

                                    <div className="step2 step" ref={step2}>


                                        <h2>Initiating Gomputer Protocol...</h2>
                                        "Send a Golemz on ze big Adventure"<br />
                                        <div className="verysmallfont">
                                            (An Ethereum Transaction needs to be signed)
                                        </div>

                                        <div className="golemzPortraitPixel">

                                            <img src={user.selectedGolemz.img} />

                                        </div>
                                        <br />
                                        {
                                            user.selectedGolemz.id >= 0 ? (
                                                <div>
                                                     <input className="setWaInput" placeholder="Enter ze Name here" ref={waInput}></input> <br /><br />
                                                    <div className="computerbutton" onClick={() => initSetWa(user.walletAddress,user.selectedGolemz.id,waInput.current.value)}>
                                                        Sign up {user.selectedGolemz.name} 
                                                    </div>
                                                    <br /><br />
                                                    <div className="verysmallfont">
                                                        Setting a blank name will remove your Golemz from ze Adventure
                                                    </div>
                                                    <br />
                                                </div>
                                            ) : (
                                                <div>  
                                                                                                     
                                                     Please select a Golemz from the Menu at the bottom first.     
                                                     <br /><br /><br />                            
                                                    
                                                </div>
                                            )
                                        }

                                        

                                        <a  onClick={() => doStep(step1, step2)}>
                                           Go back
                                        </a>

                                    </div>       

                                    <div className="step3 step" ref={step3}>

                                        <h2>Ze big Adventure</h2>

                                        <div className="">
                                            Waiting for transaction signature
                                        </div>

                                        <br /><br />

                                        <div className="">
                                            {
                                                tempGolemzname !== "" ? (
                                                    <h2>
                                                        You are signing up your Golemz by naming it:<br />
                                                        { tempGolemzname }
                                                    </h2>
                                                ) : (
                                                    <h2>
                                                        You are removing your Golemz by deleting its name!<br />                                                       
                                                    </h2>
                                                )
                                            }
                                           
                                        </div>

                                    </div>                              
                                    
                                    <div className="gobackbutton" onClick={closeGomputer}>
                                    Exit Gomputer
                                    </div>
                                </div>

                                <img src="/rooms/shower.jpg" className="bg" />

                                <div className="navigation navbottom">                                    
                                        <div className="navbutton" onClick={() => {goTo("/PoolClub/3")}}>
                                            S
                                        </div>
                                </div>
                            
                            </div>

                            <div className="specialaction hidden" ref={specialaction} onClick={() => {resetRoom();}}>
                                Call back Bruv
                            </div>                            

                    </div>


                    <div className="wrappoolclub nowrappoolclublarge"> 
                         <br />
                        <h2>
                            Degen, your screen is too small to enjoy this room.
                        </h2>
                        <h1>
                            Please switch to a bigger screen.
                        </h1>
                    </div>

                </div>


            ) : user.golemz == 0 ? (
                <div className="wrapmissinggolem">

                    <img src="/MISSINGGOLEM.png" className="missinggolem"/>

                </div>
            ) : (
                <div >
                </div>
            )}
            
        </div>
    );
};

export default RoomShower;
