import { useEffect, useState, useRef } from "react";

const PageNotFound = (props) => {

       
    return (
        <div>

            <div className="wrapmissinggolem">

            <img src="/MISSINGGOLEM.png" className="missinggolem"/>



            </div>
            
        </div>
    );
};

export default PageNotFound;
