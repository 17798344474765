import { useEffect, useState, useRef } from "react";

import {
    triggerSuccessfulEffect,
    triggerUnsuccessfulEffect
} from "./actions.js";

import { useUser } from "./User";
import {TextBox } from "./TextBox";

import { useNavigate } from 'react-router-dom';



/*
ABOUT THIS ROOM:
Z is welcoming anon in the room
*/

const RoomPoolClub2 = (props) => {

    let user = useUser();
    const navigate = useNavigate();

    useEffect(() => {

        user.setCurrentRoom("PoolClub/2");
        user.setCursor();

    }, []);
   

    /* GAME LOGIC */

    const text1 = useRef();

    const Z = useRef();

    const playMusic = useRef();
    const poolclubzone = useRef();

    const [audio] = useState(new Audio("/poolclubvibe.mp3"));
    const [audioclick] = useState(new Audio("/click.mp3"));
	const [musicplaying, setMusicPlaying] = useState(false);

    const [audioWa] = useState(new Audio("/golemzOG.wav"));

    const checkAction = (e) => {

        unsetActions(); 

        
        if(e === "playMusic")
        {
            if(user.mode === "inspect")
            {
                doInspect("A 'Beatz by Dr. Degen' Ghettoblaster");
            }
            else if(user.mode === "use")
            {
                
                if(!musicplaying)
                {
                    setMusicPlaying(true);
                    audioclick.play();	
                    window.setTimeout(() => {audio.play();}, 200);		

                    audio.addEventListener('ended', function () {
                        this.currentTime = 0;
                        this.play();
                    }, false);			
                }
                else
                {
                    setMusicPlaying(false);
                    audioclick.play();
                    window.setTimeout(() => {audio.pause();}, 200);	
                    
                }

                triggerSuccessfulEffect("*Click*",user.setEffect);
            }
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        else if (e === "clickCocktail")
        {
            if(user.mode === "inspect")
            {
                doInspect("A cocktail made with ze gud ketschup");
            }            
            else if(user.mode === "use" && user.item === "crowbar")
            {
                audioWa.play();	
                triggerSuccessfulEffect("*Pling*",user.setEffect);
                window.setTimeout(() => {navigate('/');},1500);
                audio.pause();
                setMusicPlaying(false);
            }
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        else if(e === "checkZ")
        {
            if(user.mode === "inspect")
            {
                doInspect("A Golemz named 'Z'");
            }     
            
            
        }
        else{
            triggerUnsuccessfulEffect(user.setEffect);
        }
    }

    const talkTo = (char) => {

    }

    const unsetActions = () =>
    {

    }

    const doInspect = (e) => {
        triggerSuccessfulEffect(e,user.setEffect);
    }

    const chooseText = (e,eToRemove,h) => {

        if(e === undefined)
            e = "A1";

        if(eToRemove === undefined)
            eToRemove = "A1";


        let i = document.getElementById(e); //show
        let r = document.getElementById(eToRemove); //hide

        if(i !== null && r !== null)
        {

            i.classList.remove("hidden");
            r.classList.add("hidden");      

            if(e === "A1A2B1")
            {
                window.setTimeout(() => { navigate('/');}, 1500);
                audioWa.play();	
                audio.pause();
                setMusicPlaying(false);
            }

            if(h === "happy")
            {
                Z.current.classList.add("happy");
            }
            else if(h === "unhappy")
            {
                Z.current.classList.add("unhappy");
            }
        }
            

    }

    const triggerNewScene = () => {

        poolclubzone.current.classList.add("blurout3");
        window.setTimeout(() => {navigate('/PoolClub/3');}, 3000);        
        audio.pause();
        setMusicPlaying(false);
    }
    
    return (
        <div>

            { user.golemz > 0 ? (
                <div className="wrappoolclub">    

                        <br />

                        <div className="poolclubzone" ref={poolclubzone}>

                            <div className="wraptextBubble wraptextBubbleRight" ref={text1} id="A0">
                                
                                <div className="text">
                                    Yo Degen, nice to meet you!<br />
                                    So how do you like our Pool Club?
                                </div>

                                <div className="choicebutton" onClick={ () => {chooseText("A1","A0","happy")} }>
                                    >It's really chill!
                                </div>

                                <div className="choicebutton" onClick={ () => {chooseText("B1","A0","unhappy")} }>
                                    >WTF is this shit?
                                </div>

                            </div>

                            {/*B1 unhappy path */ }

                            <div className="wraptextBubble hidden" id="B1">

                            <div className="text">
                                So this is ze secret Golemz' Pool Club. <br />
                                It's where we hang out.<br />
                                I know, it's quite fancy, right?

                                <div className="choicebutton" onClick={ () => {chooseText("A1","B1","happy")} }>
                                    >Yeah, looks really nice!
                                </div>
                            </div>

                            </div>

                                {/*A1 happy path */ }
                                <div className="wraptextBubble wraptextBubbleRight hidden" id="A1">

                                    <div className="text">
                                        Ahhh I'm glad you like it Degen.<br />
                                        Have you tasted our special cocktail already?

                                        <div className="choicebutton" onClick={ () => {chooseText("A2","A1","happy")} }>
                                            >Ohh yes plz gib more of ze Sex with ze Goblin!
                                        </div>

                                        <div className="choicebutton" onClick={ () => {chooseText("A1B1","A1","unhappy")} }>
                                            >I don't want ze Sex with ze Goblin!<br />
                                            >It tastes like Tampup!
                                        </div>
                                    </div>
                                    
                                </div> 

                                {/*A1B1 unhappy path */ }

                                <div className="wraptextBubble hidden" id="A1B1">

                                    <div className="text">
                                        Whaaaat, you don't like ze Sex with ze Golbin?!<br />
                                        Degen you must be sick or something?

                                        <div className="choicebutton" onClick={ () => {chooseText("A2","A1B1")} }>
                                            >Eeehh I mean I looove ze Sex with ze Goblin
                                        </div>

                                    </div>

                                </div>                             


                                {/*A2 happy path */ }
                                <div className="wraptextBubble wraptextBubbleRight hidden" id="A2">
                                    <div className="text">
                                        Delicious, isn't it?! <br />
                                        So Degen, d0n told me, you guys need our help?

                                        <div className="choicebutton" onClick={ () => {chooseText("A3","A2")} }>
                                            >Yeah, FFFFffuuuu Big Teck!
                                        </div>

                                        <div className="choicebutton" onClick={ () => {chooseText("A1A2B1","A2")} }>
                                            >Nahh all good!<br />
                                            >I like Big Teck!<br />
                                        </div>
                                    </div>
                                    
                                </div>
                                

                                {/*A1A2B1 unhappy path */ }

                                <div className="wraptextBubble hidden"  id="A1A2B1">

                                    <div className="text">
                                        DEGEN WTF!!!!!<br />
                                        GTFO!!!                                        
                                    </div>
                                    
                                </div>                                

                                {/*A3 happy path */ }
                                <div className="wraptextBubble wraptextBubbleRight hidden" id="A3">
                                    <div className="text">
                                        Yeahh FFFffuu that guy!<br />
                                        Okay Degen, ready to come with me?

                                        <div className="choicebutton" onClick={ () => { triggerNewScene();} }>
                                            >LET'S FUCKING GOLEM!!! (Continue)
                                        </div>

                                        <div className="choicebutton" onClick={ () => {chooseText("A1A2A3B1","A3")} }>
                                            >Wait, I want to stay here for a while
                                        </div>
                                    </div>
                                    
                                </div>


                                {/*B4 happy path */ }
                                <div className="wraptextBubble wraptextBubbleRight hidden" id="A1A2A3B1">
                                    <div className="text">
                                        Okay, cya later degen!

                                        <div className="choicebutton" onClick={ () => {chooseText("A0","A1A2A3B1")} }>
                                            >No, wait! Let's try this again.
                                        </div>

                                        <div className="choicebutton" onClick={ () => {navigate('/PoolClub');} }>
                                            >Bye Bye Z, cya
                                        </div>

                                    </div>
                                    
                                </div>


                            <img className="talkingchar talkingcharRight" src="/chars/Z.png" ref={Z} onClick={() => {checkAction("checkZ")} }/>

                            <img src="/Bruv.png" className="bruv" />

                            <img src="/beatz.png" className="playMusic" ref={playMusic} onClick={() => {checkAction("playMusic")} }/>

                            <div className="cocktail" onClick={() => {checkAction("clickCocktail")} }></div>


                            <img src="/poolclubbg.jpg" className="bg" />

                        
                        </div>

                </div>


            ) : user.golemz == 0 ? (
                <div className="wrapmissinggolem">

                    <img src="/MISSINGGOLEM.png" className="missinggolem"/>

                </div>
            ) : (
                <div >
                </div>
            )}
            
        </div>
    );
};

export default RoomPoolClub2;
