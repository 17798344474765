import { useEffect, useState, useRef, useTransition } from "react";

import {
    triggerSuccessfulEffect,
    triggerUnsuccessfulEffect
} from "./actions.js";

import { useUser } from "./User";
import {TextBox } from "./TextBox";

import {
    Link
} from "react-router-dom";

import { useNavigate } from 'react-router-dom';
  
/*
ABOUT THIS ROOM:
Z is welcoming anon in the room and explains ze Pool Club.
*/

const RoomPoolClub3 = (props) => {

    let user = useUser();

    const { canMoveWithKeys } = useUser();

    useEffect(() => {
        
        user.setCursor();
        user.setCurrentRoom("PoolClub/3");
        
        window.addEventListener('keydown', navigateWithKeys);
    
        return () => { window.removeEventListener('keydown', navigateWithKeys) ;}

    }, [user]);

    useEffect(() => {

        const caller = async () => {
           
        };
        caller().then(() => {

        });        

    }, [canMoveWithKeys]);
   

    /* GAME LOGIC */

    const text1 = useRef();
    const navigate = useNavigate();

    const Z = useRef();

    const playMusic = useRef();
    const specialaction = useRef();
    const schrubbing = useRef();

    const checkBruv = useRef();
    const Bruv = useRef();
    const Babo = useRef();
    const clickBox = useRef();
    const clickKetschup = useRef();
    const pickupKetschup = useRef();
    const talkBruv = useRef();
    const poolclubzone = useRef();

    const [audio] = useState(new Audio("/poolclubvibe.mp3"));
    const [rumble] = useState(new Audio("/sounds/rumble.mp3"));
    const [audioclick] = useState(new Audio("/click.mp3"));
	const [musicplaying, setMusicPlaying] = useState(false);
    const [canSchrub, setCanSchrub] = useState(false);
    const [zIsGone, setZIsGone] = useState(true);

    const [audioWa] = useState(new Audio("/golemzOG.wav"));
    const [schrubschrub] = useState(new Audio("/sounds/schrubschrub.mp3"));
    const [squeeze] = useState(new Audio("/sounds/squeeze.wav"));

    const resetRoom = () => {
        setCanSchrub(false);
        resetSpeechBubbles();        
        setZIsGone(false);
        Z.current.classList.remove("movecharoutbottom");
        Z.current.classList.remove("hidden");
        specialaction.current.classList.add("hidden");
        chooseText("A0","","happy");
        checkBruv.current.classList.remove("show");
    }



    const checkAction = (e) => {

        unsetActions();         


        if(!zIsGone)
        {
            if(e === "playMusic")
            {
                if(user.mode === "inspect")
                {
                    doInspect("A 'Beatz by Dr. Degen' Ghettoblaster");
                }
                else if(user.mode === "use")
                {
                    
                    if(!musicplaying)
                    {
                        setMusicPlaying(true);
                        audioclick.play();	
                        window.setTimeout(() => {audio.play();}, 200);		

                        audio.addEventListener('ended', function () {
                            this.currentTime = 0;
                            this.play();
                        }, false);			
                    }
                    else
                    {
                        setMusicPlaying(false);
                        audioclick.play();
                        window.setTimeout(() => {audio.pause();}, 200);	
                        
                    }

                    triggerSuccessfulEffect("*Click*",user.setEffect);
                }
                else{
                    triggerUnsuccessfulEffect(user.setEffect);
                }
            }        
            else if(e === "checkZ")
            {
                if(user.mode === "inspect")
                {
                    doInspect("A Golemz named 'Z'");
                } 
            }
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        else if(zIsGone)
        {
            if(e === "playMusic")
            {
                if(user.mode === "inspect")
                {
                    doInspect("A 'Beatz by Dr. Degen' Ghettoblaster");
                }
                else if(user.mode === "use")
                {
                    
                    if(!musicplaying)
                    {
                        setMusicPlaying(true);
                        audioclick.play();	
                        window.setTimeout(() => {audio.play();}, 200);		

                        audio.addEventListener('ended', function () {
                            this.currentTime = 0;
                            this.play();
                        }, false);			
                    }
                    else
                    {
                        setMusicPlaying(false);
                        audioclick.play();
                        window.setTimeout(() => {audio.pause();}, 200);	                        
                    }

                    triggerSuccessfulEffect("*Click*",user.setEffect);
                }
                else{
                    triggerUnsuccessfulEffect(user.setEffect);
                }
            }        
            else if (e=== "schrubSchrub")
            {
                if(user.mode === "inspect")
                {
                    doInspect("A Golemz named 'Bruv'");
                }
                else if(user.mode === "talk")
                {
                    resetSpeechBubbles();     
                    talkBruv.current.classList.add("show");         
                }
            }
            else if(e === "clickBox")
            {
                if(user.mode === "inspect")
                {
                    doInspect("A box with a sign \"Don't touch\"");
                } 
                else if((user.mode==="open" || user.mode==="pickup" || user.mode==="pull" || user.mode==="push") && user.item==="crowbar")
                {
                    resetSpeechBubbles();
                    clickBox.current.classList.add("show");
                    rumble.play();
                }
            }
            else if(e === "clickKetschup")
            {
                if(user.mode === "inspect")
                {
                    doInspect("This way to Buck's Cafeteria!");
                } 
                else if(user.mode==="use" && user.item==="zegudketschup")
                {
                    resetSpeechBubbles();     
                    squeeze.play();
                    clickKetschup.current.classList.add("show");               
                } 
                else if(user.mode === "pickup")
                {
                    resetSpeechBubbles();     
                    pickupKetschup.current.classList.add("show");         
                }
            }  
            else if(e === "clickBabo")
            {
                if(user.mode === "inspect")
                {
                    doInspect("A Golemz called Babo. He doesn't want to be disturbed.");
                }               
            }  
            else if(e === "privateArea")
            {
                if(user.mode === "inspect")
                {
                    doInspect("The entrance to ze Golemz' private area.");
                } 
            }        
            else{
                triggerUnsuccessfulEffect(user.setEffect);
            }
        }
        
    }

    const resetSpeechBubbles = () => {
        clickBox.current.classList.remove("show");
        clickBox.current.classList.remove("show");
        checkBruv.current.classList.remove("show");
        Bruv.current.classList.remove("schrubschrub");
        schrubbing.current.classList.remove("show");
        clickKetschup.current.classList.remove("show");
        pickupKetschup.current.classList.remove("show");
        talkBruv.current.classList.remove("show");
    }

    const checkActionMD = (e) => {

        if (e=== "schrubSchrub")
        {
            if(user.mode === "use")
            {
                resetSpeechBubbles();  
                schrubschrub.play();
                checkBruv.current.classList.remove("show");	
                Bruv.current.classList.add("schrubschrub");
                schrubbing.current.classList.add("show");

            }
        }
    }

    const checkActionMU = (e) => {

        if (e === "schrubSchrub")
        {
            if(user.mode === "use")
            {
                resetSpeechBubbles();  
                schrubschrub.pause();
            }	           
        }
    }


    const talkTo = (char) => {

    }

    const unsetActions = () =>
    {

    }

    const doInspect = (e) => {
        triggerSuccessfulEffect(e,user.setEffect);
    }

    const chooseText = (e,eToRemove,h) => {

        if(e === undefined)
            e = "";

        if(eToRemove === undefined)
            eToRemove = "";

        let i;
        let r;

        if(e !== "")
            i = document.getElementById(e); //show

        if(eToRemove !=="" )
            r = document.getElementById(eToRemove); //hide


        if(i !== null && i !== undefined)
            i.classList.remove("hidden");

        if(r !== null && r !== undefined)
            r.classList.add("hidden"); 
        

        if(h === "happy")
        {
            Z.current.classList.add("happy");
        }
        else if(h === "unhappy")
        {
            Z.current.classList.add("unhappy");
        }


        /* SPECIAL CONDITIONS */

        if(e === "A0")
        {
            Z.current.classList.remove("flipchar");
            Z.current.classList.add("talkingcharRight");
            Z.current.classList.remove("talkingcharLeft");
        }

        if(e === "A1")
        {
            Z.current.classList.add("flipchar");
            Z.current.classList.add("talkingcharLeft");
            Z.current.classList.remove("talkingcharRight");
        }

        if(e === "A2")
        {
            Z.current.classList.remove("flipchar");
            Z.current.classList.remove("talkingcharLeft");
            Z.current.classList.add("talkingcharRight");
        }

        if(e === "A5")
        {
            Z.current.classList.add("flipchar");
            Z.current.classList.add("talkingcharLeft");
            Z.current.classList.remove("talkingcharRight");
        }      
        
        if(e === "A7")
        {
            Z.current.classList.add("movecharoutbottom");

            window.setTimeout(() => {
                checkBruv.current?.classList.add("show");
                setCanSchrub(true);
                setZIsGone(true);
                specialaction.current?.classList.remove("hidden");
                specialaction.current?.classList.add("fadein");            
            },2500);
        }


    }

    const goTo = (roomUrl) => {
        navigate(roomUrl);
        /*poolclubzone.current.classList.add("blurout3");
        window.setTimeout(() => {}, 3000);    */    

        setMusicPlaying(false);
        audio.pause();
    }

    const navigateWithKeys = (event) => {

        if(user.canMoveWithKeys)
        {
            switch( event.keyCode ) {
                case 87:
                    goTo("/Shower");
                    break;
                case 68:
                    goTo("/Cafeteria");
                    break;
                case 65:
                    goTo("/Shop");
                    break;
                default: 
                    break;
            }
        }

    }



    
    return (
        <div>

            { user.golemz > 0 ? (
                <div >
                    <div className="wrappoolclub wrappoolclublarge blurinroom" >    


                    
                            <div className="poolclubzonelarge" ref={poolclubzone}>

                                <div className="navigation navtop">
                                        <div className="navbutton" onClick={() => {goTo("/Shower")}}>
                                            W
                                        </div>
                                </div>
                                
                                <div className="navigation navright">
                                        <div className="navbutton" onClick={() => {goTo("/Cafeteria")}}>
                                            D
                                        </div>
                                </div>

                                <div className="navigation navleft">
                                        <div className="navbutton" onClick={() => {goTo("/Shop")}}>
                                            A
                                        </div>
                                </div>

                                <div className="wraptextBubble wraptextBubbleRight hidden" id="A0">
                                    
                                    <div className="text">
                                        G(ole)m Degen, let me explain ze rooms of<br />
                                        ze Pool Club to you.
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A1","A0","happy")} }>
                                        >I'm ready
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A1">
                                    
                                    <div className="text">
                                        Here on the right, you can go to ze Cafeteria.<br />
                                        This is where we eat and talk shit once we're done with ze Shower...<br />
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A12","A1","happy")} }>
                                        >Is ze Cafeteria open?!
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A12">
                                    
                                    <div className="text">
                                        Yes, Buck's Cafeteria is finally open again! <br />
                                        Thanks to Frank who has cleaned up all of ze gud Ketschup...<br />
                                        <span className="green">Press D</span> if you want to go talk to Buck!
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A2","A12","happy")} }>
                                        >Cool! Anything else I can do?
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleRight hidden" id="A2">
                                    
                                    <div className="text">
                                        On the left we have Bomber's Shop!<br />
                                        Bomber was away for a while, but he's finally back and will probably<br />
                                        open ze Shop again soon.
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A4","A2","happy")} }>
                                        >Great! What else is here?
                                    </div>

                                </div>                               

                                <div className="wraptextBubble wraptextBubbleRight hidden" id="A4">
                                    
                                    <div className="text">
                                        Finally straight in the middle is ze Shower.<br />
                                        I think d0n has fixed ze Shower and reconnected ze Gomputer, <br />
                                        so you could go have a look by <span className="green">pressing 'W'.</span>
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A5","A4","happy")} }>
                                        >What? A Shower?
                                    </div>

                                </div>

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A5">
                                    
                                    <div className="text">
                                        WwaaaraaAAAaawwrrraAA Yes!!! <br />
                                        In ze Shower, we ready up for our big adventure. <br />
                                        Just check it out, Bruv will join you and explain the rest...
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A6","A5","happy")} }>
                                        >Will do! Can I do something else here?
                                    </div>

                                </div>                                

                                <div className="wraptextBubble wraptextBubbleLeft hidden" id="A6">
                                    
                                    <div className="text">
                                        Don't think so, but you can always join Bruv in ze Pool<br />
                                        and rub his ass with ze gud ketschup.
                                    </div>

                                    <div className="choicebutton" onClick={ () => {chooseText("A7","A6","happy")} }>
                                        >Noooooooo
                                    </div>

                                </div>

                                <img className="talkingchar talkingcharRight hidden" src="/chars/Z.png" ref={Z} onClick={() => {checkAction("checkZ")} }/>


                                <div ref={checkBruv} className="speechbubble speechbubble-bruv-3" style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-bruv-text">
                                        Degen, <br />
                                        I could use a little<br />
                                        massage over here!!!
                                    </div>

                                </div>

                                <div ref={clickBox} className="speechbubble speechbubble-bruv-4" style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-bruv-text">
                                        Hey Degen, I think <br />you're not allowed<br />to touch that...
                                    </div>

                                </div>

                                <div ref={clickKetschup} className="speechbubble speechbubble-bruv-5" style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-bruv-text">
                                        Somebody <br /> spilled a ton of<br />
                                        ze gud ketschup there a while ago...<br />
                                        Please don't <br />do that again!
                                    </div>

                                </div>

                                <div ref={pickupKetschup} className="speechbubble speechbubble-bruv-5" style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-bruv-text">
                                        Didn't d0n already give<br/>
                                        you a bottle of<br />
                                        ze gud Ketschup?
                                    </div>

                                </div>

                                <div ref={talkBruv} className="speechbubble speechbubble-bruv-5" style={{background: 'url("/speech-bubble.svg")'}}>

                                    <div className="speechbubble-bruv-text">
                                        I like you Degen...<br />
                                        Soon we will kick<br />
                                        Big Teck in his balls.<br />
                                        That will be<br />
                                        AWESOME.
                                    </div>

                                </div>

                                
                                <div ref={schrubbing} className="soundeffect soundeffect-schrub">
                                    *SCHRUBSCHRUB*
                                </div>

                                <div className="clickBox" onClick={() => {checkAction("clickBox")} }></div>
                                <div className="clickKetschup" onClick={() => {checkAction("clickKetschup")} }></div>      
                                <div className="privateArea" onClick={() => {checkAction("privateArea")} }></div>                           

                                <img src="/Bruv.png" className="bruv" ref={Bruv} onClick={() => {checkAction("schrubSchrub")}} onMouseDown={() => {checkActionMD("schrubSchrub")}} onMouseUp={() => {checkActionMU("schrubSchrub")}} />

                                <img src="/beatz.png" className="playMusic" ref={playMusic} onClick={() => {checkAction("playMusic")} }/>

                                <img src="/Babo.png" className="babo" ref={Babo} onClick={() => {checkAction("clickBabo")}} />

                                <div className="cocktail" onClick={() => {checkAction("clickCocktail")} }></div>

                                <img src="/rooms/poolclub.jpg" className="bg" />
                            
                            </div>

                            
                            <div className="specialaction fadein" ref={specialaction} onClick={() => {resetRoom();}}>
                                Call Z
                            </div>
                            

                    </div>

                    <div className="wrappoolclub nowrappoolclublarge"> 
                         <br />
                        <h2>
                            Degen, your screen is too small to enjoy this room.
                        </h2>
                        <h1>
                            Please switch to a bigger screen.
                        </h1>
                    </div>

                </div>


            ) : user.golemz === 0 ? (
                <div className="wrapmissinggolem">

                    <img src="/MISSINGGOLEM.png" className="missinggolem" alt="Missing Golem"/>

                </div>
            ) : user.golemz === -1 ? (
                <div className="wrapmissinggolem">                    

                </div>
            ) : (
                <div className="wrapmissinggolem">

                    <img src="/MISSINGGOLEM.png" className="missinggolem" alt="Missing Golem"/>

                </div>
            )}
            
        </div>
    );
};

export default RoomPoolClub3;
