const TermsOfService = (props) => {
    return (
        <div>
            <div className="golemcontainer">

                <div className="divider"></div>
                <a href="/">Back</a>

                <div className="divider"></div>
                <h2>Terms of</h2><br ></br>
                <h1>Service</h1> 
                <br />               
                <p className="">by  <a href="https://twitter.com/donbuidl0r" target="_blank">donatell0</a></p> 
                "I am a hacker, not a lawyer..."<br />

                <br />

                <div className="goopapertext ">
                    <br />
                    <h1 className="tiltleft">&nbsp;&nbsp;Please read</h1>
                    <br /><br />
                    <div className="normalfont">
                    <p>
                    "We are ze Golemz" is an interactive comic, game and digital experience that includes a collection of digital artworks (NFTs) running on the Ethereum network. <br />
                    This website serves as an interface allowing participants to use their NFTs and experience the game and Story. 
                    Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. 
                    Furthermore, as the "We are ze Golemz" smart contract (Golemz or GMZ) runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.
                    <br /><br />
                    This website and its connected services are provided “as is” and “as available” without warranty of any kind. 
                    By using this website you are accepting sole responsibility for any and all transactions involving "We are ze Golemz" NFTs.
                    <br /><br />

                    <h2>Naming Golemz</h2>
                    <h3>VERY VERY VERY IMPORTANT:</h3>
                    "We are ze Golemz" is a Story driven game that is written, drawn, animated and programmed live in reaction to the Community's actions. 
                    <br />
                    You can sign up your Golemz to the Story by using the function setWwaaaraaAAAaawwrrraAA() inside of the smart contract. Using this function means that your Golemz will receive a "Name".<br /><br />
                    Special conditions apply for signed up / named Golemz:<br />
                    <b>Signed up Golemz may die during the Story.</b><br /><br />
                    A dead Golem may be removed from your wallet ("burned") or its related metadata may be replaced. By signing up a Golemz to the Story you agree to knowing the risks and that you may lose your asset forever.<br />
                    Please note that a signed up Golemz will never die without a reason, and that every death can always be prevented if the NFT Holder reacts to the challenges he is confronted with correctly.<br />
                    I will never kill a signed up Golemz or remove it from your wallet for any other reason than its death as part of the Story. <br />I will never kill a Golemz that has not signed up to the Story.<br /><br />
                    You can decide to remove a Golemz from the Story at any time, by resetting his name using the setWwaaaraaAAAaawwrrraAA() function.<br /><br />
                    If a Golemz is currently signed up can be identified by using getWwaaaraaAAAaawwrrraAA() function.<br /><br />
                    Finally, please note that naming and unnaming (signing up and removing) a Golemz from the Story costs gas fees on the Ethereum network.

                    <br /><br /><br />
                    <h2>Ownership</h2>

                    For as long as you hold the NFT, the following ownership conditions apply:<br /><br />

                    i. Due to possibility to sign up Golemz to the Story and killing them, I, Donatell0, technically own all the Golemz. Each Golemz is an NFT on the Ethereum blockchain. <br /><br />

                    ii. You lease the NFT. When you purchase an NFT, you lease the underlying Golemz, the "Art" from me, Donatell0. This leasing of the NFT is mediated by the Smart Contract and the Ethereum Network. Holding the NFT in your wallet means that you are currently leasing the NFT from me, Donatell0.<br />For the sake of clarity, please note again, that this leasing contract may be terminated, by me, Donatell0, at any time, if you, the leaser, decide to name a Golemz but do not react to the challenges that you're confronted with during a certain period of time in the Story. The same is true if you decide to buy a Golemz that has already been named, but do not remove the name of this Golemz. I will never terminate a leasing contract for a reason unrelated to the Story.<br /><br />

                    iii. Personal Use. Subject to your continued compliance with these Terms and during the lease of your NFT, I, Donatell0 grant you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your Golemz / NFT, provided that the marketplace cryptographically verifies each Golemz owner’s rights to display the "Art" for their Golemz to ensure that only the actual owner can display the "Art"; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your Golemz, provided that the website/application cryptographically verifies each Golemz owner’s rights to display the "Art" for their Golemz to ensure that only the actual owner can display the "Art", and provided that the "Art" is no longer visible once the owner of the Golemz leaves the website/application.<br /><br />

                    iv. Commercial Use. Subject to your continued compliance with these Terms and during the lease of your NFT, I, Donatell0, grant you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the "Art" (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the "Art" to produce and sell merchandise products (T-Shirts etc.) displaying copies of the "Art". For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and sale of Golemz generally, provided that the marketplace cryptographically verifies each Golemz owner’s rights to display the "Art" for their Golemz to ensure that only the actual owner can display the "Art"; (ii) owning or operating a third party website or application that permits the inclusion, involvement, or participation of Golemz generally, provided that the third party website or application cryptographically verifies each Golemz owner’s rights to display the "Art" for their Golemz to ensure that only the actual owner can display the "Art", and provided that the "Art" is no longer visible once the owner of the Purchased Golemz leaves the website/application; or (iii) earning revenue from any of the foregoing.<br /><br />
                    

                    </p>
                    </div>
                </div>

                <div className="divider"></div> 

                <p className="whomadezegolemz">ze golemz by <a href="https://twitter.com/donbuidl0r" target="_blank">donatell0</a></p>
               
            </div>
        </div>
    );
};

export default TermsOfService;
