import { useEffect, useState, useRef } from "react";


const AGudStory = (props) => {

        
    return (
        <div className="wrapagudstory">
            <br />
            <h2>Read ze "A Gud Story"</h2>  

                <a href="https://twitter.com/zegolemz" target="_blank">
                    Follow ze Story on Bird App
                </a>
                <br /><br />
                <a href="/"><div className="button leavebuttonblack">
                    Close
                </div>
            </a>
            <br /><br />

            <h3>Intermission Part 2</h3>

            <a href="/AGudStory/2" className="button leavebuttonblack">
                { "< Back" }
            </a>
            
            <div className="agudchapter">

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission2/Intro.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/Intro2.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        We see an absurd scene inside ze Bank...<br />
                        Something is coming.
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission2/1.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/2.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/3.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/4.jpg" className="agudimgsmall"></img>
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        1
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission2/5.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/6.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/7.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/8.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/9.jpg" className="agudimg"></img>
                    

                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        2
                    </div>
                </div>

                <div className="agudchapterinside">
                    <div className="activation">
                       Back in ze Pool Club, or rather in ze Cave, something is knocking at the door to ze City...
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission2/10.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/11.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                      It's Bomber!
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission2/12.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/13.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/14.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/15.jpg" className="agudimgsmall"></img>  
        
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        3
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission2/16.jpg" className="agudimgsmall"></img>    
                    <img loading="lazy" src="/AGudStory/Intermission2/17.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/18.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/19.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/20.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/21.jpg" className="agudimgsmall"></img>

                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        4
                    </div>
                </div>

                <div className="agudchapterinside">
                    <div className="activation">
                      Bomber arrives back at ze Pool Club and picks up his box...
                    </div>  
                    <img loading="lazy" src="/AGudStory/Intermission2/22.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/23.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                      and despite a sign cleary saying 'Do not touch' Anon can't remember whether he touched it or not...
                    </div> 
                    <img loading="lazy" src="/AGudStory/Intermission2/24.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/25.jpg" className="agudimgsmall"></img>   
                    <img loading="lazy" src="/AGudStory/Intermission2/26.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/27.jpg" className="agudimgsmall"></img>                
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        5
                    </div>
                </div>

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission2/28.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/29.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/30.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/31.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                       Thanks to the help of Anon and Brother Claude, ze Box is opened using a key... <br />
                       *RUMBLE*
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission2/32.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/33.jpg" className="agudimgsmall"></img>
                    
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        6
                    </div>
                </div>

                <div className="agudchapterinside">

                    <img loading="lazy" src="/AGudStory/Intermission2/34.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/35.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                       Even Bomber is surprised...
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission2/36.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/37.jpg" className="agudimgsmall"></img>     
                    <img loading="lazy" src="/AGudStory/Intermission2/38.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/39.jpg" className="agudimgsmall"></img>  
        
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        7
                    </div>
                </div>

                <div className="agudchapterinside">                    
                    <img loading="lazy" src="/AGudStory/Intermission2/40.jpg" className="agudimg"></img>
                    <div className="activation">
                       Z takes ze Weapons into his lab for a deep analysis.<br />
                       He explains each weapon in a <a href="https://twitter.com/zegolemz/status/1590333515459354625" target="_blank">Thread on Bird App</a>
                    </div> 
                    <img loading="lazy" src="/AGudStory/Intermission2/41.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/42.jpg" className="agudimgsmall"></img> 
                    <img loading="lazy" src="/AGudStory/Intermission2/43.jpg" className="agudimgsmall"></img>                    
                    <img loading="lazy" src="/AGudStory/Intermission2/44.jpg" className="agudimgsmall"></img>                 
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        8
                    </div>
                </div>

                <div className="agudchapterinside">
                    
                    <img loading="lazy" src="/AGudStory/Intermission2/45.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/ShopUnlocked.jpg" className="agudimgsmall"></img> 
                    <div className="activation">
                       After analysing ze Weapons and making sure they're intact<br />Bomber's shop can finally open! <br />At the same time, something is happening outside in ze Cave...
                    </div> 
                    <img loading="lazy" src="/AGudStory/Intermission2/46.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/47.jpg" className="agudimgsmall"></img> 
                    <img loading="lazy" src="/AGudStory/Intermission2/48.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/49.jpg" className="agudimgsmall"></img>       
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        9
                    </div>
                </div>

                <div className="agudchapterinside">
                   
                    <img loading="lazy" src="/AGudStory/Intermission2/50.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/51.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                        Hector is hungry. Thanks to Anon he finds some strange baits to attach to his fishing rod.
                    </div>
                    <img loading="lazy" src="/AGudStory/Intermission2/52.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/53.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/54.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/55.jpg" className="agudimgsmall"></img>   

                    <div className="activation">
                        The first bait suggested by Brother Claude does not do the trick...
                    </div> 

                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        10
                    </div>
                </div>  

                 <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission2/54.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/55.jpg" className="agudimgsmall"></img>                    
                    <img loading="lazy" src="/AGudStory/Intermission2/56.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/57.jpg" className="agudimgsmall"></img>
                    <div className="activation">
                       The second bait, suggested by jonnythe0wl looks more promising...
                    </div>
                    
                    <img loading="lazy" src="/AGudStory/Intermission2/58.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/59.jpg" className="agudimgsmall"></img>     
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        11
                    </div>
                </div>   
                <div className="agudchapterinside">
                       
                    <img loading="lazy" src="/AGudStory/Intermission2/60.jpg" className="agudimg"></img>                    
                    <img loading="lazy" src="/AGudStory/Intermission2/61.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/62.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/63.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/64.jpg" className="agudimgsmall"></img>                    
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        12
                    </div>
                </div>   

                <div className="agudchapterinside">
                    <img loading="lazy" src="/AGudStory/Intermission2/65.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/66.jpg" className="agudimgsmall"></img>                    
                    <img loading="lazy" src="/AGudStory/Intermission2/67.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/68.jpg" className="agudimgsmall"></img>
                    <img loading="lazy" src="/AGudStory/Intermission2/snapshot.jpg" className="agudimg"></img>

                    <div className="activation">
                        On 30/12 2022 16H00 CET a snapshot of all ze Golemz was taken with D0n's Gamera...<br />
                        Is ze gud Story to be continued? <br />
                        We'll see!
                    </div>
                    
                    <div className="pagenumber">
                        <div className="footnote">
                            golemz.wtf
                        </div>
                        13
                    </div>
                </div>      


                

            </div>

            <h3>Summary of Intermission 1</h3>

            <div className="wrapsummary">

                <div className="summaryblock">

                    <img loading="lazy" loading="lazy" src="/AGudStory/Intermission2/6.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Ze Bankerz are here</div>
                    
                </div>
                
                <div className="summaryblock">

                    <img loading="lazy" loading="lazy" src="/AGudStory/Intermission2/unlocks/ZeToken.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Ze Bankerz have ze Token</div>  

                </div>


                <div className="summaryblock">

                    <img loading="lazy" src="/AGudStory/Intermission2/unlocks/ZEWEAPONS.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Ze weapons were found</div>   
                    
                </div>

                <div className="summaryblock">

                    <img loading="lazy" src="/AGudStory/Intermission2/unlocks/ShopUnlocked.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Bomber's Shop has opened</div>   
                    
                </div>

                <div className="summaryblock">
                    <img loading="lazy" loading="lazy" src="/AGudStory/Intermission2/63.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Hector found Don's Backpack</div>
                </div>

                
                <div className="summaryblock">

                    <img loading="lazy" src="/AGudStory/Intermission2/64.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">Ze Gamera was inside ze Backpack</div>   
                    
                </div>

                <div className="summaryblock">
                    <img loading="lazy" loading="lazy" src="/AGudStory/Intermission2/snapshot.jpg" className="summaryitem"></img><br />

                    <div className="summaryitemline">A snapshot was taken!</div>
                </div>

                <div className="clearfix"></div>

            </div>
            
            <br /><br />
            <h3>To be continued?</h3>

            <br />
            <a href="https://sudoswap.xyz/#/browse/buy/0x6503bf993a1529314337f357847b1B042f85E116" target="_blank">
                Get a Golemz and become part of ze Story
            </a><br /><br />
            <a href="https://twitter.com/zegolemz" target="_blank">
                Follow ze Story on Bird App
            </a>
            
        </div>
    );
};

export default AGudStory;
