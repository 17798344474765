
import "./App.css";
import AGudStoryC from "./AGudStoryC";
import AGudStoryC2 from "./AGudStoryC2";
import AGudStoryC3 from "./AGudStoryC3";


import { useEffect, useState} from "react";

import {useLocation} from 'react-router-dom';

const AGudStory = (props) => {

    const [path, setPath] = useState("");

  const location = useLocation();

    useEffect(() => {
        const caller = async () => {
            console.log(path);
            setPath(location.pathname.toLowerCase());
        };

        caller().then(() => {
            console.log(path);
            setPath(location.pathname.toLowerCase());
        });

        return () => {
            
        };
    }, [location]);

    return (
        <div className="agudstoryc">
                   

            <div> 
                {path === "/agudstory/3" ? (
                    <AGudStoryC3 />
                ) : path === "/agudstory/2" ? (                            
                    <AGudStoryC2 />                          
                ) : (
                    <AGudStoryC />
                )}    

            </div>
                
            
        </div>
    );

};


export default AGudStory;
