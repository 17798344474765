import axios from "axios";

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

let contractABI = require("../abis/contract-abi.json"); 

const contractAddress = process.env.REACT_APP_CONTRACT; 
const strapitoken = process.env.REACT_APP_STRAPI_TOKEN;


export const setGolemzData = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    let amounts = [];
    let golemzmax = 300;

    let names = 0;

    for(let i = 0; i<golemzmax; i++)
    {
        try {
                let config = {
                    method: "get",
                    url: `https://strapi.golemz.wtf/api/golemzs?filters[tokenId][$eq]=`+i,

                };

                await axios(config)
                // eslint-disable-next-line no-loop-func
                .then(async (response) => {

                    //UPDATE A GOLEM
                    console.log("RE",response.data.data);

                    let id = response.data.data[0].id;

                    let copper = await window.contract.methods
                    .getZeCopper(i)
                    .call(function (error, result) {
                        if (!error) return result;
                        else return "";
                    });

                    let name = await window.contract.methods
                    .getWwaaaraaAAAaawwrrraAA(i)
                    .call(function (error, result) {
                        if (!error) return result;
                        else return "";
                    });

                    try
                    {
                        await axios.put(`https://strapi.golemz.wtf/api/golemzs/`+id, {
                            headers: {
                                Authorization:
                                'Bearer '+strapitoken,
                            },                            
                            "data" : {
                                "tokenId": i,
                                "copper": copper,
                                "name": name,
                            }                            
                            }
                        ).then(async (response) => {
                            console.log("RESPONSE",response);
                        })
                    } catch (error){
                        console.log("CANNOT UPDATE",error)
                    }

                    if(name !== "")
                    {
                        names++;
                        console.log(name);
                    }
                    

                })               
            
        } catch (error) {
            console.log("SETTING NEW GOLEMZ", error);
            let name = "";

            //SET NEW GOLEM
            
            try
            {
                await axios.post(`https://strapi.golemz.wtf/api/golemzs/`, {
                    headers: {
                        Authorization:
                        'Bearer '+strapitoken,
                    },
                    
                    "data" : {
                        "tokenId": i
                    }
                    
                    }
                ).then(async (response) => {
                    console.log("RESPONSE",response);
                })
            } catch (error){
                console.log("ERROR SETTING NEW GOLEMZ",error);
            }

            
        }
    }

    console.log(names);


    /*let tokens = await axios({
        method: 'get',
        url: 'https://thesph3res.goneon.lu/parseSph3res.php',
        responseType: 'json'
      })
        .then(function (response) {
         return response.data;
        });

    var BreakException = {};
    await tokens.forEach(function(e){ 
        let tId = parseInt(e.id.tokenId);   

        setNameDB(tId);

    });*/

};

