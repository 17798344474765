import { useEffect, useState, useRef } from "react";

const RiddleC = (props) => {


    const [codeCorrect, setCodeCorrect] = useState(null);
    const input = useRef();

    

    const checkCode = () => {
        let currentCode = input.current.value;

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: "input="+currentCode 
        };

        fetch("https://api.golemz.wtf/riddles/zecode.php", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {

                if(result.Result == "CONGRATZGOLEMZ")
                {
                    setCodeCorrect(true);
                }
                else
                {
                    setCodeCorrect(false);
                }
            }
        );
    }
    
    return (
        <div>
            <div className="golemcontainer">    
                    <br />
                    <h2>Enter ze Code</h2>            

                    <input className="riddleinput" name="input" ref={input}/>
                    <br />

                    <div className="riddlebutton button" onClick={() => {checkCode();}}>
                    ENTER
                    </div>    

                    {codeCorrect === true && (
                        <div>
                            <img className="riddleresult" src="zeStory/zeCodeIzCorrect.jpg" />
                        </div>
                    )}  

                    {codeCorrect === false && (
                        <div>
                            <img className="riddleresult" src="zeStory/thatsnotit.png" />
                        </div>
                    )}         

            </div>
        </div>
    );
};

export default RiddleC;
