import axios from "axios";

import { useUser } from "./User";

let effectField;

const defaultNothingText = "*Nothing happens*";



export const triggerSuccessfulEffect = (text, setEffect) => {
    setEffect(text);
    effectField = document.getElementById('effectfield');
    
    effectField.classList.remove('success');

    effectField.classList.add("success");
    effectField.addEventListener("animationend", () =>
    {
        effectField = document.getElementById('effectfield');
        effectField.classList.remove('success');
    })        


}

export const triggerUnsuccessfulEffect = (setEffect) => {
    setEffect(defaultNothingText);

    effectField = document.getElementById('effectfield');

    effectField.classList.remove('active');

    effectField.classList.add("active");
    effectField.addEventListener("animationend", () =>
    {
        effectField = document.getElementById('effectfield');
        effectField.classList.remove('active');
    })  
}





