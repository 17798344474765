import axios from "axios";

/*
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
*/

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const DEV = process.env.REACT_APP_DEV;

//For getNFTS
const apiKey = process.env.REACT_APP_ALCHEMY_KEY2;
let baseURL = `https://eth-mainnet.g.alchemy.com/v2/${apiKey}/getNFTs/`;

let contractABI = require("../abis/contract-abi.json"); 

/*
if(DEV)
{
    console.log("DEV MODE ENABLED");
    contractABI = require("../abis/dev-contract-abi.json"); 
    baseURL = `https://eth-goerli.g.alchemy.com/v2/${apiKey}/getNFTs/`;
}*/

const contractAddress = process.env.REACT_APP_CONTRACT; 

const poolAddress = "0x43d1262197ae2d0a6a968343c4478b662c3cdfe3";

const sudoswap = require("@musedao/sudoswap.js");

const sudo = new sudoswap(`https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY2}`); //RPC and optional private key if used for sending transactions

export const getSudoPoolPrice = async () => {

    const pool = sudo.getPool(poolAddress);

    let price = await pool.getSpotPrice();

    let rp = web3.utils.fromWei(String(price),"ether");

    rp = String(rp).substr(0,7);

    return rp;
}


export const connectWallet = async () => {
    
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "",
                address: addressArray[0],
            };
            return obj;
        } catch (err) {
            return {
                address: "",
                status: err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <div>
                    <p>
                        {" "}
                        🦊{" "}
                        <a
                            target="_blank"
                            href={`https://metamask.io/download.html`}
                        >
                           Please install ze Metamask.
                        </a>
                    </p>
                </div>
            ),
        };
    }
};

export const disconnectWallet = async () => {};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });

            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "",
                };
            } else {
                return {
                    address: "",
                    status: "connect wallet.",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <div>
                    <p>
                        {" "}
                        🦊{" "}
                        <a
                            target="_blank"
                            href={`https://metamask.io/download.html`}
                        >
                            Please install ze Metamask.
                        </a>
                    </p>
                </div>
            ),
        };
    }
};

export const getWa = async (tokenid) => {
    window.contract = await new web3.eth.Contract(
        contractABI,
        contractAddress,
    );

    try {
        return await window.contract.methods
            .getWwaaaraaAAAaawwrrraAA(tokenid)
            .call(function (error, result) {
                if (!error) return result;
                else return -1;
            });
    } catch (error) {
        console.log("No golemz", error);
        return 0;
    }

}

export const getGolemz = async (address) => {
    if (address !== "") {
        window.contract = await new web3.eth.Contract(
            contractABI,
            contractAddress,
        );

        try {
            return await window.contract.methods
                .balanceOf(address)
                .call(function (error, result) {
                    if (!error) return result;
                    else return -1;
                });
        } catch (error) {
            console.log("No golemz", error);
            return 0;
        }
    }
};

export const getGolemzList = async (address) => {
    if (address !== "") {

        const ownerAddr = address;
        const contractAddr = contractAddress;

        let config = {
            method: "get",
            url: `${baseURL}?owner=${ownerAddr}&contractAddresses[]=${contractAddr}`,
        };

        return await axios(config)
            .then(async (response) => {
                let golemzArray = [];
                for (let i = 0; i < response.data.ownedNfts.length; i++) { 

                    let tokenid = parseInt(response.data.ownedNfts[i].id.tokenId);
                    
                    window.contract = await new web3.eth.Contract(
                        contractABI,
                        contractAddress,
                    );
            
                    try {
                        response.data.ownedNfts[i].name = await window.contract.methods
                            .getWwaaaraaAAAaawwrrraAA(tokenid)
                            .call(function (error, result) {
                                if (!error) 
                                {
                                    return result;
                                }
                                else return "";
                            });
                    } catch (error) {
                        console.log("Can't get WwaaaraaAAAaawwrrraAA", error);                        
                    }  
                    
                    golemzArray.push({
                        ...response.data.ownedNfts[i]
                    }); 
                    
                }

                return { ...response.data, ownedNfts: golemzArray };
            })
        .catch((error) => console.log("Error in getGolemzList", error));

    }
};


export const setWa = async (walletAddress,tokenid,name) => {

    if(isAlphanumeric(name) || name === "")
    {
        window.contract = await new web3.eth.Contract(
            contractABI,
            contractAddress,
        );
    
        const transactionParameters = {
            to: contractAddress,
            from: walletAddress,
            data: window.contract.methods
                .setWwaaaraaAAAaawwrrraAA(tokenid, name)
                .encodeABI(),
        };
    
        try {
            const txHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [transactionParameters],
            });
            
            return {
                success: true,
                status:
                    "<br /><div className='loader center'><span /><span /></div><h3>Naming your Golemz:</h3><h2><a href='https://etherscan.io/tx/" +
                    txHash +
                    "' target='_blank'>Track transaction on Etherscan</a></h2>",
                hash: txHash,
            };

        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message,
                hash: "",
            };
        }

    }
    else
    {
        return false;
    }

    
};

function isAlphanumeric(str) {
    return /^[a-z0-9?, _-]+$/i.test(str)
}


export const waitForGolemzNamed = async (hash, setGolemzShowering, finishShower) => {
    let counter = 0;

    const interval = setInterval(function () {


        /* DEV
        finishShower();
        clearInterval(interval); */


        web3.eth.getTransactionReceipt(hash).then((res) => {
            counter++;
            if (res) {
                clearInterval(interval);
                finishShower();
            }

            if (counter > 1000) {
                alert(
                    "Something went wrong. Your transaction seems to be stuck. Please check your wallet.",
                );
                clearInterval(interval);
                setGolemzShowering(false);
            }
        });
    }, 1000);
};


